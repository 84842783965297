<ng-template #Title>
    {{ ((title || '') | translate).format(context) }}
</ng-template>

<ng-template #Outlet let-template="template" let-context="context">
    @if (isTemplateRef(template)) {
    <ng-container *puTemplateOutlet="template; injector: injector; context: context;"></ng-container>
    } @else if(isComponentType(template)) {
    <ng-container *ngComponentOutlet="template; injector: injector; inputs: componentInputs(template);"></ng-container>
    } @else {

    }
</ng-template>

@if (templateTitle !== false) {
<div mat-dialog-title cdkDrag cdkDragHandle cdkDragBoundary="body" cdkDragRootElement=".mat-mdc-dialog-container"
    class="w-100 px-3 py-0" [cdkDragDisabled]="maximized" (dblclick)="resiable && (maximized = !maximized)">
    <div class="pb-2 d-flex flex-row align-items-center">
        <div class="flex-fill text-center">
            <ng-container *puTemplateOutlet="Outlet; context:{
                template: templateTitle || Title,
                context: context
            }"></ng-container>
        </div>

        @if (resiable) {
        <label class="btn btn-sm btn-primary mx-1 p-0 lh-1" (click)="maximized = !maximized">
            <i class="material-icons">
                {{ maximized ? "zoom_in_map" : "zoom_out_map" }}
            </i>
        </label>
        }

        <label mat-dialog-close class="btn btn-sm btn-primary mx-1 p-0 lh-1">
            <i class="material-icons">close</i>
        </label>
    </div>
    <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
</div>
}

<div mat-dialog-content class="overflow-hidden position-relative flex-fill w-100 m-0 px-0 py-0">
    <ng-container [puTemplateOutlet]="Outlet" [puTemplateOutletContext]="{
        template: templateContent,
        context: context
    }"></ng-container>
</div>

@if (templateFooter) {
<ng-container *puTemplateOutlet="Outlet; context:{
    template: templateFooter,
    context: context
}"></ng-container>
}