import { merge, Observable, ObservableInput, of, Subject, Subscription } from "rxjs";
import { catchError, finalize, map, shareReplay, switchMap } from "rxjs/operators";
import { EventEmitter, TemplateRef, Type } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { A, O } from "ts-toolbelt";
import { Moment } from "moment";

const InheritKey: unique symbol = Symbol('InheritKey');

type EI<T extends Editor.Editor<any, any>> = Editor.EI<T>;

type RType<T> = T extends null | undefined ? never : T;

type ClsType<T> = Type<T>;

type IOP = {
    (obj?: object): void;
    preval?: any;
};

export interface IDestroies<T extends Editor.Editor<T, EI<T>>> extends Array<IOP> {
    host: {
        [Key in Editor.TKeys<T>]?: {
            callback: IOP,
            data?: any
        }
    }
}

type IContext<T extends Editor.Editor<T, EI<T>>> = {
    memberscancel?: IMembersCancel<T>,
    membersowned?: IMembersOwned<T>,
    createnew?: IOP,
}

type IMembersOwned<T extends Editor.Editor<T, EI<T>>> = {
    [Key in Editor.TKeys<T>]?: Editor.Editor<any> | Array<Editor.Editor<any>>
}

type IMembersCancel<T extends Editor.Editor<T, EI<T>>> = {
    [Key in Editor.TKeys<T>]?: IOP;
}

interface Prop {
    handler?: _Editor.IHandle<any>;
    type: Editor.Type;
    settings?: any;
}

interface isReadonly {
    (obj: object): boolean;
}

const _FieldBag = Prop.Slot<{
    isReadonly: ReturnType<typeof Editor.isReadonly>,
    readonlys: { [P: string | symbol]: isReadonly },
    prop: { [P: string | symbol]: Prop },
}>('FIELDKEYS');

export namespace Editor {
    let cud: TSettingHandler | undefined = undefined;

    export const detect = {
        get cud(): TSettingHandler | undefined {
            return cud;
        },
        set cud(val: TSettingHandler | undefined) {
            cud = val;
        }
    }
}

export namespace Editor {
    export class Editor<T extends Editor<T, TI>, TI = any> {
        readonly $props_ = Prop.Of<{}, Editor.TProp<T>>(this) as Editor.TProp<T>;
        readonly $eprops_ = Prop.Of<{}, _Editor.EProp<T>>(this.$props_) as _Editor.EProp<T>;

        get $presave_(): EventEmitter<{
            handled?: boolean | Editor<any> | Observable<{
                handled?: boolean | Editor<any>
            }>
        }> {
            const { $eprops_: eprops } = this;
            return eprops.$presave_ = eprops.$presave_ || new EventEmitter();
        }

        get $postsave_(): EventEmitter<boolean> {
            const { $eprops_: eprops } = this;
            return eprops.$postsave_ = eprops.$postsave_ || new EventEmitter();
        }

        get $precancel_(): EventEmitter<string> {
            const { $eprops_: eprops } = this;
            return eprops.$precancel_ = eprops.$precancel_ || new EventEmitter();
        }

        get $postcancel_(): EventEmitter<string> {
            const { $eprops_: eprops } = this;
            return eprops.$postcancel_ = eprops.$postcancel_ || new EventEmitter();
        }

        get $temp_(): TProp<T> {
            const { $eprops_: eprops } = this;
            return eprops.$temp_ = (eprops.$temp_ || {}) as any;
        }

        get $setting_(): TSetting<T> {
            const { $eprops_: eprops } = this;
            return eprops.$setting_;
        }

        get $base_(): T | undefined {
            const { $eprops_: { $setting_: setting }, $thistype_: thiscls } = this;
            return setting instanceof thiscls ? setting : undefined;
        }

        get $inherits(): TProp<T> | undefined {
            return this.$eprops_.$inherits_;
        }

        get $thistype_(): ClsType<T> {
            return (this as any).constructor;
        }

        get $forcreate(): boolean {
            const { $eprops_: { $$context_: ctx } } = this;
            return !!ctx.createnew;
        }

        set $forcreate(val: boolean) {
            const { $eprops_: { $$context_: ctx } } = this;
            if (!!ctx.createnew && val) return;

            if (val) {
                ctx.createnew = ctx.createnew || _.noop;
            } else {
                delete ctx.createnew;
            }
        }

        get $json(): {
            readonly exclude: {
                [P in TKeys<T>]: boolean
            }
        } {
            const { $eprops_: eprops } = this;
            if (eprops.$json) return eprops.$json;

            const exclude = {}, json = (eprops.$json = {
                get exclude(): {
                    [P in TKeys<T>]: boolean
                } {
                    return exclude as any;
                }
            } as any);

            return json;
        }

        get $saving_(): boolean {
            return this.$eprops_.$$saving_.level > 0;
        }

        $pushSaving_() {
            this.$eprops_.$$saving_.push();
        }

        $popSaving_() {
            this.$eprops_.$$saving_.pop();
        }

        constructor(setting?: (T | TI) & {
            [InheritKey]?: TProp<T>,
            $inherits?: TProp<T>
        }) {
            const { $eprops_: eprops, $thistype_: thisType } = this;
            setting = (setting || <Exclude<typeof setting, undefined>>{});

            // extract the inherit props, and free
            if (setting[InheritKey]) {
                eprops.$inherits_ = setting[InheritKey];
                delete setting[InheritKey];
            }

            if (setting['$inherits']) {
                eprops.$inherits_ = { ...(setting['$inherits'] || {}), ...(eprops.$inherits_ || {}) }
            }

            // the destroies/context interoperations
            eprops.$$context_ = eprops.$$context_ || {};
            eprops.$setting_ = setting as TSetting<T>;
            eprops.$$destroies_ = eprops.$$destroies_ || _.extend([], { host: {} });
            if (eprops.$setting_ instanceof thisType) {
                // build the same life-cycle.
                _Editor.$destroy_ref(this as any, eprops.$setting_);
            }

            // the saving_ chain
            const _this = this, chain = (eprops.$$saving_ = {
                level: 0,
                push() {
                    ++chain.level;

                    const { $eprops_: { $$context_: { membersowned } } } = _this;
                    for (let k in (membersowned || {})) {
                        _Editor.ownedsaving((membersowned as any)[k], true);
                    }
                },
                pop() {
                    if (chain.level > 0) {
                        --chain.level;

                        const { $eprops_: { $$context_: { membersowned } } } = _this;
                        for (let k in (membersowned || {})) {
                            _Editor.ownedsaving((membersowned as any)[k], false);
                        }
                    }
                },
            });

        }

        $onvalue_?(key: string | number | symbol, value: any, changed?: boolean): void;

        $readonly_(key: string | number | symbol): boolean {
            return !!isReadonly(this)?.is(key);
        }

        $changed_(key?: TKeys<T>): boolean {
            const { $temp_: temp, $eprops_: { $$context_: { createnew, membersowned, memberscancel } } } = this;

            return key ? (
                temp.hasOwnProperty(key) || _Editor.ownedchanged((membersowned as any)?.[key]) || !!memberscancel?.[key]
            ) : (
                Object.keys(temp).length > 0 || !!createnew || !!memberscancel || _.some(membersowned || {}, o => _Editor.ownedchanged(o))
            );
        }

        $cancel_(key?: TKeys<T>): void {
            if (!this.$changed_()) return;

            const { $saving_: saving, $temp_: temp, $eprops_: { $$context_: context, $$saving_ } } = this;
            const { $eprops_: { $$context_: { memberscancel, membersowned, createnew } } } = this;

            $$saving_.push();

            if (key) {
                this.$precancel_.emit(key as string);
                if (memberscancel?.[key]) {
                    memberscancel[key]?.();
                    delete memberscancel[key];
                }

                if (membersowned?.[key]) {
                    _Editor.ownedcancel(membersowned[key]);
                }

                delete temp[key];

                $$saving_.pop();
                this.$postcancel_.emit(key as string);
                return;
            }

            if (!saving) {
                this.$precancel_.emit();
            }

            if (!saving && createnew) {
                createnew?.();
            }

            if (!saving && memberscancel) {
                _.forEach(memberscancel, o => o?.());
            }

            if (!saving && membersowned) {
                _.forEach(membersowned || {}, o => _Editor.ownedcancel(o))
            }

            for (let k in temp) {
                delete (temp as any)[k]
            }

            delete context.memberscancel;
            delete context.createnew;
            $$saving_.pop();

            if (!saving) {
                this.$postcancel_.emit();
            }
        }

        $preval_<Key extends TKeys<T>>(key: Key): TProp<T>[Key] {
            const { prop } = _FieldBag.Of(this, false) || {};
            const handler = prop?.[<string>key]?.handler;
            return handler?.prevalget?.call(this, key);
        }

        $save_(state?: { drilling?: boolean }): boolean | Observable<boolean> {
            if (!this.$changed_()) return false;

            const next = (handled?: boolean | Editor<any>) => {
                if (_.isBoolean(handled) && handled) {
                    this.$postsave_.emit(true);
                    return true;
                }

                if (handled instanceof Editor) {
                    const ret = handled.$save_();
                    if (ret instanceof Observable) {
                        const onsave = new Subject<boolean>();
                        const { $postsave_ } = this;
                        const sub = ret.subscribe({
                            next(succeed: boolean) {
                                $postsave_.emit(succeed);
                                tick(() => sub.unsubscribe());
                                onsave.next(true);
                                onsave.complete();
                            },
                            error(error) {
                                $postsave_.emit(false);
                                onsave.error(error);
                                onsave.complete();
                            }
                        });

                        return onsave;
                    }

                    return ret;
                }

                // Backend service update succeed, update locally, need to 
                // remove override object/parameter according to response from backend.
                const {
                    $temp_: temp,
                    $eprops_: {
                        $$saving_,
                        $$context_: {
                            membersowned,
                            createnew,
                        },
                    }
                } = this;

                $$saving_.push();

                // call the backend service to update the service data, 
                // backend need to consider remove the override object/parameter.
                const onresult = (value?: any, succeed: boolean = true) => {
                    if (succeed) {
                        this.$update_(value, true);

                        // save into editor object.
                        _.forEach(membersowned || {}, (o) => {
                            _Editor.ownedsave(o);
                        })

                        _.forEach(temp, (val, key) => {
                            (this as any)[key] = val ?? value?.[key]
                        });

                        // discard the temp values
                        this.$cancel_();
                    }

                    // restore the saving state.
                    $$saving_.pop();

                    if (succeed) {
                        this.$update_(value, false);
                    }

                    this.$postsave_.emit(succeed);
                    return succeed;
                }

                const changed = JSON.JsonOf(this);
                const entity = detect.cud?.$entity?.(this);
                const ret: boolean | Observable<any> | undefined = entity && !state?.drilling && (
                    createnew ? detect.cud?.$create?.(changed, entity) :
                        detect.cud?.$update?.(changed, entity)
                );

                if (ret instanceof Observable) {
                    const onsave = new Subject<boolean>();
                    const _ret = ret.pipe(
                        map((value: any): boolean => {
                            onresult(value, true);
                            onsave.next(true);
                            onsave.complete();
                            return true;
                        }),
                        catchError((error: any) => {
                            onresult(undefined, false);
                            onsave.error(error);
                            onsave.complete();
                            return of(false);
                        }),
                        finalize(() => {
                            tick(() => _sub.unsubscribe());
                        }),
                        shareReplay(1)
                    ), _sub = _ret.subscribe();

                    return onsave;
                }

                return onresult(changed, true);
            }

            const handle: {
                handled?: boolean | Editor<any> | Observable<{
                    handled?: boolean | Editor<any>
                }>
            } = {};

            !state?.drilling && this.$presave_.emit(handle);
            if (!(handle.handled instanceof Observable)) {
                return next(handle.handled);
            }

            const ret = handle.handled.pipe(
                switchMap(({ handled }): ObservableInput<boolean> => {
                    const value = next(handled);
                    if (value instanceof Observable) {
                        return value;
                    }

                    return of(!!value);
                }),
                catchError(() => {
                    return of(false);
                }),
                finalize(() => {
                    tick(() => sub.unsubscribe());
                }),
                shareReplay(1)
            ), sub = ret.subscribe();

            return ret;
        }

        $destroy_() {
            const { $eprops_: { $$destroies_, $$destroies_: { host } } } = this;
            const { array: destroies } = $$destroies_;
            _.forEach([...destroies], cb => cb(this));
            _.forEach(host, (cb) => cb?.callback(this));
        }

        $update_(value: T | TI | undefined, precommit: boolean) {
            if (!precommit || !_.isObject(value)) return;

            // TODO: currently we only update the object id even not enough for sync over cross-operation
            const { $setting_: setting, $eprops_: {
                $$context_: { membersowned }
            } } = this;

            // save into editor object.
            _.forEach(membersowned || {}, (o, key) => {
                _Editor.ownedupdate(o, (value as any)[key])
            })

            if ((value as any)["id"] != (setting as any)["id"]) {
                (setting as any)["id"] = (value as any)["id"];
            }
        }

        static UpdateIfHave<
            D extends Object,
            S extends {
                [P in keyof D]?: any
            },
            H extends {
                [P in keyof D]?: (dst: D, val: any) => any
            }
        >(dst: D, src: S, handle: H) {
            _.forEach(handle, (h, k) => {
                if (_.has(src, k)) {
                    h?.(dst, _.get(src, k));
                }
            })
        }
    }
}

export namespace Editor {
    export function Collections<
        T extends Editor<any, any>,
        TI = T
    >(clsVal?: ClsType<T>) {
        return function (cls: ClsType<any>) {
            const { prototype } = cls, { createnew } = prototype as Array<any>;

            Object.defineProperty(prototype, 'createnew', {
                enumerable: true, configurable: false,
                value(this: T[], val: TI | T, at?: number): T {
                    const newone: T = createnew.call(this, val || {}, at);
                    newone.$eprops_.$$context_.createnew = _Editor.refcreate_cancel(
                        this, newone
                    );

                    return newone;
                }
            })
        }
    }
}

export namespace Editor {
    export interface IDynamic {
        [key: string]: any;
    }

    export class Dynamic<TParent> extends Editor<Dynamic<TParent>, IDynamic> implements IDynamic {
        get $parent_(): TParent {
            return this.$props_.$parent_!;
        }

        get $fields_(): IField[] {
            return this.$props_.$fields_!;
        }

        get $exclude_(): {
            [P: string]: boolean
        } {
            const { $props_: props } = this;
            return props.$exclude_ || (
                props.$exclude_ = {}
            )
        }

        // @ts-ignore
        readonly $json: {
            readonly exclude: {
                [P in TKeys<Dynamic<TParent>>]: boolean
            } & {
                [P: string]: boolean
            }
        }

        get $fieldtypes_(): Dynamic.IFieldTypes | undefined {
            return this.$props_.$fieldtypes_;
        }

        constructor(
            setting: IDynamic,

            parent: TParent,
            fields: IField[],
            fieldtypes?: Dynamic.IFieldTypes
        ) {
            super(setting);

            const { $props_: props } = this;
            props.$fieldtypes_ = fieldtypes;
            props.$fields_ = _.clone(fields);
            props.$parent_ = parent;

            // build the properties according to fields
            (fields || []).forEach(f => {
                Dynamic.build<Dynamic<TParent>, TParent>(this, parent, f, fieldtypes);
            });

            if ((fields || []).length <= 0) {
                Object.defineProperty(this, 'toJSON', {
                    writable: true, enumerable: false, configurable: false,
                    value(this: any, res: { [P: string]: any } = {}) {
                        return;
                    }
                })
            }
        }
    }

    export namespace Dynamic {
        export type IFieldTypes = {
            [P in Value.Type]?: {
                new(parent: any, setting?: any): any
            }
        }

        export function build<
            T extends Editor<T, EI<T>>, TParent
        >(obj: T, parent: TParent, field: IField, fieldtypes?: Dynamic.IFieldTypes, settings?: {}) {
            if (!obj) return;

            const fieldkey = field.key;
            if (!fieldkey) return;

            const valmtyp: Value.Type = Value.getFieldType(field, Value.Type.multi);
            const ismulti = Value.isFieldType(field, Value.Type.multi);
            const thisType: ClsType<T> = (obj as any).constructor;
            const valtyp: Value.Type = Value.getFieldType(field);
            const fldtyp = fieldtypes?.[valmtyp];

            if (ismulti) {
                const setting = Owneds(fieldkey as any, {
                    ...(settings || {}),
                    creator(this: T) {
                        return fldtyp ? new fldtyp(parent) : [];
                    }
                });

                Field(thisType, setting)(obj, fieldkey);
            } else if (fldtyp) {
                const setting = Owned(fieldkey as any, {
                    ...(settings || {}),
                    itemtype: () => fldtyp,
                    creator(this: T, item: any) {
                        return new fldtyp(parent, item);
                    }
                });

                Field(thisType, [Type.owned, valtyp], setting)(obj, fieldkey);
            } else {
                const { source } = field;
                const setting = Owned<T, TKeys<T>>(fieldkey as any, {
                    ...(settings || {}),
                } as any);

                Field(thisType, [Type.owned, valtyp as Value.Type.enum, source, 0], setting)(obj, fieldkey);
            }

            JSON.Key()(obj, fieldkey);
        }
    }
}

export namespace Editor {

    function buildReadonlys(prop: { [P: string]: Prop; }): { [P: string]: isReadonly } {
        return _.reduce(prop, (res, { handler: { isReadonly } = <_Editor.IHandle<any>>{} }, key) => {
            if (isReadonly) {
                res[key] = isReadonly.bind(undefined, key)
            }

            return res;
        }, <{ [P: string]: isReadonly }>{})
    }

    export function isReadonly(obj: object): {
        handles: { [P: string | number | symbol]: isReadonly } | undefined
        handle(key: string | number | symbol): isReadonly | undefined
        is(key: string | number | symbol): boolean
    } | undefined {
        const bag = _FieldBag.Of(obj, false);
        if (!bag) return;

        const robag = (bag.isReadonly || (
            bag.isReadonly = {
                is(key: string | number | symbol): boolean {
                    const bag = _FieldBag.Of(obj, false);
                    if (!bag) return false;

                    const { prop } = bag;
                    const func = prop[key]?.handler?.isReadonly;
                    return !!func?.(key, obj);
                },
                handle(key: string | number | symbol): isReadonly | undefined {
                    return robag?.handles?.[key];
                },
                get handles(): {
                    [P: string | number | symbol]: isReadonly
                } | undefined {
                    const bag = _FieldBag.Of(obj, false);
                    if (!bag) return;

                    return (bag.readonlys || (
                        bag.readonlys = buildReadonlys(bag.prop)
                    ));
                }
            }
        ))

        return robag;
    }

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: Type.guidid): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: Type.numberid): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: Type.owned): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type.enum, any, number]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: [Type.owneds, Value.Type.enum, any]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: Type.inherit): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: [Type.inherit, Value.Type]): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>
    >(cls: ClsType<TP>, type: [Type.inherit, Value.Type.enum, any, number]): PropertyDecorator;

    // owned
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>
    >(cls: ClsType<TP>, type: Type.owned, settings: _Editor.Owned<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>
    >(cls: ClsType<TP>, settings: _Editor.Owned<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type], settings: _Editor.Owned<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>
    >(cls: ClsType<TP>, type: [Type.owned, Value.Type.enum, any, number], settings: _Editor.Owned<TP, Key>): PropertyDecorator;

    // owneds
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(cls: ClsType<TP>, type: [Type.owneds, Value.Type.enum, any], settings: _Editor.Owneds<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(cls: ClsType<TP>, type: Type.owneds, settings: _Editor.Owneds<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(cls: ClsType<TP>, settings: _Editor.Owneds<TP, Key>): PropertyDecorator;

    // refowned
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc
    >(cls: ClsType<TP>, type: Type.refowned, settings: _Editor.RefOwned<TP, Key, TSrc>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc
    >(cls: ClsType<TP>, settings: _Editor.RefOwned<TP, Key, TSrc>): PropertyDecorator;

    // refowneds
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(cls: ClsType<TP>, type: Type.refowneds, settings: _Editor.RefOwneds<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(cls: ClsType<TP>, settings: _Editor.RefOwneds<TP, Key>): PropertyDecorator;

    // refinherit
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc
    >(cls: ClsType<TP>, type: Type.refinherit, settings: _Editor.RefInherit<TP, Key, TSrc>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc
    >(cls: ClsType<TP>, settings: _Editor.RefInherit<TP, Key, TSrc>): PropertyDecorator;

    // refinherits
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc
    >(cls: ClsType<TP>, type: Type.refinherits, settings: _Editor.RefInherits<TP, Key, TSrc>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc
    >(cls: ClsType<TP>, settings: _Editor.RefInherits<TP, Key, TSrc>): PropertyDecorator;

    // refparent
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>
    >(cls: ClsType<TP>, type: Type.refparent, settings: _Editor.RefParent<TP, Key>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>
    >(cls: ClsType<TP>, settings: _Editor.RefParent<TP, Key>): PropertyDecorator;

    // refflattens
    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc, TTree
    >(cls: ClsType<TP>, type: Type.refflattens, settings: _Editor.RefFlattens<TP, Key, TSrc, TTree>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc, TTree
    >(cls: ClsType<TP>, settings: _Editor.RefFlattens<TP, Key, TSrc, TTree>): PropertyDecorator;

    export function Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TSrc, TTree
    >(cls: ClsType<TP>, type: _Editor.TypeSetting<TP, Key, Type> | Type | [Type, Value.Type] | [Type, Value.Type.enum, any, number] | [Type.owneds, Value.Type.enum, any, []?], settings?: _Editor.TypeSetting<TP, Key, Type>): PropertyDecorator {
        settings = settings || (_.isArray(type) || !_.isObject(type) ? settings : type);
        type = _.isArray(type) || !_.isObject(type) ? type : settings!.type;

        return _Field<TP, Key, TSrc, TTree>(cls, type, settings);
    }

    function _Field<
        TP extends Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TSrc, TTree
    >(cls: ClsType<TP>, type: Type | [Type, Value.Type] | [Type, Value.Type.enum, any, number] | [Type.owneds, Value.Type.enum, any, []?], settings?: _Editor.TypeSetting<TP, Key, Type>): PropertyDecorator {

        const editortype = _.isArray(type) ? type[0] : type;
        const valuetype = (_.isArray(type) ? type[1] : 0) as Value.Type;
        const enumtype = _.isArray(type) ? type[2] : undefined;
        const valuedef = _.isArray(type) ? type[3] : undefined;

        function cast<T>(setting: any): T | undefined {
            if (setting == null) return;
            return setting;
        }

        const numberid: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting } = this;
                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                return setting[key] = setting[key] ?? Prop.idx() as any;
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                return numberid.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                return true;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude } } = obj;
                if (exclude[key]) return;

                const { $saving_: saving, $eprops_: { $$context_: { createnew } } } = obj;
                if (saving && createnew) return;

                return JSON.JsonOf(obj.$setting_[key]);
            }
        }

        const guidid: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting } = this;
                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                return setting[key] = setting[key] ?? Prop.uuid(this, '') as any;
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                return guidid.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                return true;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude } } = obj;
                if (exclude[key]) return;

                const { $saving_: saving, $eprops_: { $$context_: { createnew } } } = obj;
                if (saving && createnew) return;

                return JSON.JsonOf(obj.$setting_[key]);
            }
        }

        const owned: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting, $eprops_: { $$context_: context, $$saving_ } } = this;
                const reffer = cast<_Editor.Owned<TP, Key, TSrc>>(settings);
                const getter = reffer?.getter?.call(this);
                const itemtype = reffer?.itemtype?.();
                const creator = reffer?.creator;

                if (getter) {
                    return setting[key] = getter();
                }

                if (owned.isReadonly?.(key, this) && !!(setting instanceof cls)) {
                    return setting[key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL object access exception, need further solution.
                    return undefined as any;
                }

                // check the raw config
                if (creator && (itemtype ? (
                    !(setting[key] instanceof itemtype)
                ) : (
                    setting[key] == null
                ))) {
                    $$saving_.push();

                    // not yet create, create it.
                    const value = setting[key] = creator.call(this, setting[key] as TSrc);

                    if (value instanceof Editor) {
                        context.membersowned = context.membersowned || {};
                        context.membersowned[key] = value;
                    }

                    $$saving_.pop();
                }

                return (setting[key] = Value.toValue(valuetype, setting[key], enumtype, valuedef)) as TP[Key];
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp, $setting_: setting } = this;
                const reffer = cast<_Editor.Owned<TP, Key>>(settings);
                const getter = reffer?.getter?.call(this);

                if (!(getter || (owned.isReadonly?.(key, this) && !!(setting instanceof cls))) && temp.hasOwnProperty(key)) {
                    return temp[key] = Value.toValue(valuetype, temp[key], enumtype, valuedef);
                }

                return owned.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            set<Key extends TKeys<TP>>(this: TP, key: Key, value: TP[Key]) {
                if (owned.isReadonly?.(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting, } = this;
                const reffer = cast<_Editor.Owned<TP, Key>>(settings);
                const itemtype = reffer?.itemtype?.();

                if (itemtype && !(value instanceof itemtype)) {
                    // reset the setting
                    setting[key] = value;
                    return;
                }

                if (saving) {
                    setting[key] = value;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;
                if (owned.isReadonly?.(key, obj) && !!(setting instanceof cls)) return;

                if (saving) {
                    $$saving_.push();
                    const v = JSON.JsonOf(Value.fromValue(valuetype, obj[key] ?? undefined, enumtype, valuedef));
                    $$saving_.pop();
                    return v;
                }

                // not saving mode, seialize all.
                if (setting[key] == null) return;
                return JSON.JsonOf(Value.fromValue(valuetype, setting[key], enumtype, valuedef));
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const reffer = cast<_Editor.Owned<TP, Key>>(settings);
                if (reffer?.hasOwnProperty('isReadonly')) {
                    if (_.isFunction(reffer.isReadonly)) {
                        return reffer.isReadonly();
                    }

                    return !!reffer.isReadonly;
                }

                const { $setting_: setting } = obj;
                const getter = reffer?.getter?.call(obj);
                return !!(getter || setting instanceof cls);
            }
        }

        const owneds: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel?.[key];
                if (iop) return iop.preval;

                return owneds.get!.call<TP, [Key], TP[Key]>(this, key);
            },
            get<Key extends TRefsKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.Owneds<TP, Key>>(settings);
                const creator = reffer?.creator;

                if (props[key]) return props[key]!;

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize the owneds array for next round trial.
                    //       may cause NULL array access exception, need further solution
                    return undefined as any;
                }

                $$saving_.push();

                const values = ((props[key] = (
                    creator?.call(this) || []
                ) as RType<TP[Key]>) as Array.ItemType<TP[Key]>[]);

                if (enumtype && Array.isArray(setting[key])) {
                    setting[key]?.forEach(s => {
                        values.push(Value.toValue(valuetype, s, enumtype, valuedef))
                    })
                } else {
                    values.recreate.apply(values, setting[key] || [] as any)
                }

                const ret = buildOwneds(this, values, key, false) as RType<TP[Key]>;
                return $$saving_.pop(), ret;
            },
            toJSON<Key extends TRefsKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = owneds.get!.call<TP, [Key], TP[Key]>(obj, key) as Array.ItemType<TP[Key]>[];
                let v: JSON.Type;
                if (values?.length > 0) {
                    $$saving_.push();

                    v = values.map(v => v && JSON.JsonOf(
                        enumtype ? Value.fromValue(valuetype, v, enumtype, valuedef) : v
                    )).filter(v => v != undefined);

                    $$saving_.pop();
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const inherit: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $eprops_: eprops, $setting_: setting } = this;
                const inherits = eprops.$inherits_;

                if (!!(setting instanceof cls)) {
                    if (inherits?.hasOwnProperty(key)) props[key] = inherits[key], delete inherits[key];
                    return props[key] === undefined ? setting[key] : (props[key] = Value.toValue(valuetype, props[key], enumtype, valuedef));
                }

                return (setting[key] = Value.toValue(valuetype, setting[key], enumtype, valuedef)) as TP[Key];
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key] = Value.toValue(valuetype, temp[key], enumtype, valuedef);
                }

                return inherit.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            set<Key extends TKeys<TP>>(this: TP, key: Key, value: TP[Key]) {
                const { $saving_: saving, $temp_: temp, $props_: props, $eprops_: eprops, $setting_: setting } = this;
                const inherits = eprops.$inherits_;

                if (saving) {
                    if (!!(setting instanceof cls)) {
                        inherits && (delete inherits[key]);
                        props[key] = value;
                    } else {
                        setting[key] = value;
                    }

                    return;
                }

                if (value == (setting instanceof cls ? (props[key] === undefined ? setting[key] : props[key]) : setting[key])) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $props_: props, $setting_: setting, $saving_: saving } = obj;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    $$saving_.push();
                    const v = JSON.JsonOf(Value.fromValue(valuetype, obj[key] ?? undefined, enumtype, valuedef));
                    $$saving_.pop();
                    return v;
                }

                if (!!(setting instanceof cls)) {
                    // inherit object
                    const inherited = props.hasOwnProperty(key) && props[key] !== undefined;
                    return inherited ? JSON.JsonOf(Value.fromValue(valuetype, props[key], enumtype, valuedef)) : undefined;
                }

                // primary object
                if (setting[key] == null) return;
                return JSON.JsonOf(Value.fromValue(valuetype, setting[key], enumtype, valuedef));
            }
        }

        const refowned: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefOwned<TP, Key, TSrc>>(settings);
                const getter = reffer?.getter?.call(this);
                const itemtype = reffer?.itemtype?.();

                if (getter) {
                    // specified getter and just return the result
                    const v = getter();
                    if (v) {
                        return setting[key] = v;
                    }
                }

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (!!(itemtype && setting[key] instanceof itemtype)) {
                    _Editor.$destroy_refowned<TP, Key>(this, key, setting[key] as TP[Key]);
                    return setting[key] as TP[Key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return undefined as any;
                }

                $$saving_.push();

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                const sources = reffer?.source?.call(this);
                const id = (setting[key] as any)?.['id'];

                if (!sources?.firstOf || id == null) {
                    return $$saving_.pop(), undefined as any;
                }

                const value = sources.firstOf({ id }) as TP[Key];
                _Editor.$destroy_refowned<TP, Key>(this, key, value);
                return $$saving_.pop(), setting[key] = value;
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $temp_: temp } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key]!;
                }

                return refowned.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            set<Key extends TKeys<TP>>(this: TP, key: Key, value: TP[Key]) {
                if (refowned.isReadonly?.(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting } = this;
                if (saving) {
                    _Editor.$destroy_refowned<TP, Key>(this, key, value);
                    setting[key] = value;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    return { id: JSON.JsonOf((obj[key] as any)?.['id']) };
                }

                return { id: JSON.JsonOf((setting[key] as any)?.['id']) };
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const reffer = cast<_Editor.RefOwned<TP, Key, TSrc>>(settings);
                const getter = reffer?.getter?.call(obj);
                const { $setting_: setting } = obj;

                return !!(getter || setting instanceof cls);
            }
        }

        const refowneds: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel?.[key];
                if (iop) return iop.preval;

                return refowneds.get!.call<TP, [Key], TP[Key]>(this, key);
            },
            get<Key extends TRefsKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefOwneds<TP, Key, TSrc>>(settings);
                const creator = reffer?.creator;

                if (props[key]) return props[key] as any;

                if (!!(setting instanceof cls)) {
                    return setting[key] as any;
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return undefined as any;
                }

                $$saving_.push();

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       may cause NULL array access exception, need further solution.
                const sources = reffer?.source?.call(this);
                if (!sources?.firstOf) {
                    return $$saving_.pop(), undefined as any;
                }

                const values = ((props[key] = (
                    creator?.call(this) || []
                ) as RType<TP[Key]>) as Array.ItemType<TP[Key]>[]);

                const rawval = ((setting[key] || []) as { id: any }[]);
                const tgtval = rawval.map(item => item?.id && sources.firstOf({ id: item.id }));
                values.reset(...tgtval.filter(item => !!item))

                const ret = buildOwneds(this, values, key, true);
                return $$saving_.pop(), ret as TP[Key];
            },
            toJSON<Key extends TRefsKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = owneds.get!.call<TP, [Key], TP[Key]>(obj, key) as Array.ItemType<TP[Key]>[];
                let v: JSON.Type;
                if (values?.length > 0) {
                    v = values.map(v => v && (v as any)['id'] && {
                        id: JSON.JsonOf((v as any)['id'])
                    }).filter(v => !!v);
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refparent: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] | undefined {
                const { $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefParent<TP, Key>>(settings);
                const itemtype = reffer?.itemtype?.();

                if (!!(setting instanceof cls)) {
                    return setting[key];
                }

                if (!!(itemtype && setting[key] instanceof itemtype)) {
                    _Editor.$destroy_refparent(this, key, setting[key] as TP[Key], reffer);
                    return setting[key] as TP[Key];
                }

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return;
                }

                $$saving_.push();

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                const sources = reffer?.source?.call(this);
                const id = (setting[key] as any)?.['id'];

                if (!sources?.firstOf || id == null) {
                    return $$saving_.pop(), undefined;
                }

                const value = sources.firstOf({ id }) as TP[Key];
                _Editor.$destroy_refparent(this, key, value, reffer);
                return $$saving_.pop(), setting[key] = value;
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] | undefined {
                const { $temp_: temp, } = this;

                if (temp.hasOwnProperty(key)) {
                    return temp[key];
                }

                return refparent.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            set<Key extends TKeys<TP>>(this: TP, key: Key, value?: TP[Key]) {
                if (refparent.isReadonly?.(key, this)) return;

                const { $saving_: saving, $temp_: temp, $setting_: setting } = this;
                const reffer = cast<_Editor.RefParent<TP, Key>>(settings);
                value = value || reffer?.root?.call(this);

                if (saving) {
                    _Editor.$destroy_refparent(this, key, value, reffer);
                    setting[key] = value as any;
                    return;
                }

                if (value == setting[key]) {
                    this.$cancel_(key);
                    return;
                }

                temp[key] = value;
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const reffer = cast<_Editor.RefParent<TP, Key>>(settings);
                const root = reffer?.root?.call(obj);

                if (saving) {
                    return {
                        id: JSON.JsonOf(
                            ((root != obj[key]) ? (obj[key] as any)?.['id'] : undefined) ?? undefined
                        )
                    };
                }

                if ((setting[key] as any)?.['id'] == null) {
                    return;
                }

                return {
                    id: JSON.JsonOf(
                        ((root != setting[key]) ? (setting[key] as any)?.['id'] : undefined) ?? undefined
                    )
                };
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refinherit: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] | undefined {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefInherit<TP, Key, TP[Key]>>(settings);

                if (props[key] !== undefined) return props[key];

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    return;
                }

                $$saving_.push();

                let source = setting[key] as TP[Key];
                if (!(setting instanceof cls)) {
                    const id = (setting[key] as any)?.["id"];

                    // TODO: invalid sources may encounter for it's setting load failed from network liked.
                    //       not critical for no crash liked problem, but user will confuse for incorrect object displaying
                    const sources = id && reffer?.source?.call(this);

                    if (!sources?.firstOf) {
                        return $$saving_.pop(), undefined;
                    }

                    const prop = { ...(setting[key] || {}) };
                    delete (prop as any)['id'];

                    const has = Object.keys(prop).length > 0;

                    source = sources.firstOf({ id });
                    source && has && ((source as any)[InheritKey] = prop);
                }

                _Editor.$destroy_refinherit(this, key, source, reffer);
                props[key] = (source && reffer?.creator?.call(this, source)) ?? undefined;

                return $$saving_.pop(), props[key];
            },
            get<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] | undefined {
                const { $temp_: temp, } = this;
                if (temp.hasOwnProperty(key)) return temp[key];
                return refinherit.prevalget!.call<TP, [Key], TP[Key]>(this, key);
            },
            set<Key extends TKeys<TP>>(this: TP, key: Key, value: TP[Key]) {
                const { $saving_: saving, $temp_: temp, $props_: props, $eprops_: { $$context_: context } } = this;
                const reffer = cast<_Editor.RefInherit<TP, Key, TP[Key]>>(settings);

                if (saving) {
                    if (props[key] != value) {
                        _Editor.$destroy_refinherit(this, key, (value as any)?.['$base_'], reffer);
                        props[key] = value;
                    }

                    return;
                }

                if (value == (props[key] as any)?.['$base_']) {
                    this.$cancel_(key);
                    return;
                }

                if (temp.hasOwnProperty(key) && value == (temp[key] as any)?.['$base_']) {
                    return;
                }

                _Editor.$destroy_(temp[key]);
                context.memberscancel = context.memberscancel || {};
                temp[key] = value && reffer?.creator?.call(this, value);
                context.memberscancel[key] = _Editor.$destroy_cancel(temp[key]);
            },
            toJSON<Key extends TKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $props_: props, $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                if (saving) {
                    $$saving_.push();
                    const v = JSON.JsonOf(obj[key], { id: JSON.JsonOf((obj[key] as any)?.['id'] ?? undefined) });
                    $$saving_.pop();
                    return v;
                }

                if ((props[key] as any)?.["id"] == null) return;
                return JSON.JsonOf(props[key], { id: JSON.JsonOf((props[key] as any)?.['id']) });
            }
        }

        const refinherits: _Editor.IHandle<TP> = {
            prevalget<Key extends TKeys<TP>>(this: TP, key: Key): TP[Key] {
                const { $eprops_: { $$context_: { memberscancel } } } = this;
                const iop = memberscancel?.[key];
                if (iop) return iop.preval;

                return refinherits.get!.call<TP, [Key], TP[Key]>(this, key);
            },
            get<Key extends TRefsKeys<TP>>(this: TP, key: Key): Array.ItemType<TP[Key]>[] | undefined {
                const { $props_: props, $eprops_: eprops, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefInherits<TP, Key, TSrc>>(settings);

                if (props[key]) return props[key] as any;

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return;
                }

                $$saving_.push();

                const reffield: Array.ItemType<TP[Key]>[] = (setting instanceof cls) && setting[key] as any;
                const values = (props[key] = reffer?.creator?.call(this)) as Array.ItemType<TP[Key]>[];
                const inherits = eprops.$inherits_;

                if (!!(setting instanceof cls)) {
                    const ivals: Array.ItemType<TP[Key]>[] = inherits?.[key] as any;
                    inherits && (delete inherits[key]);

                    values.create(!ivals ? reffield : reffield.map(source => {
                        const prop = ivals.find(v => (v as any)['id'] == (source as any)?.['id']);
                        return source && prop && ((source as any)[InheritKey] = prop), source;
                    }));
                } else {
                    // TODO: invalid sources may encounter for it's setting load failed from network liked.
                    //       may cause NULL array access exception, need further solution.
                    const sources = reffer?.source?.call(this);
                    if (!sources?.firstOf) {
                        return delete props[key], $$saving_.pop(), undefined;
                    }

                    const defs = (setting[key] || []) as Array.ItemType<TP[Key]>[];
                    const herits = defs.map(item => {
                        const id = (item as any)?.['id'];
                        const source = id && sources.firstOf({ id }) as Array.ItemType<TP[Key]>;
                        const prop = { ...(item || {}) };
                        delete (prop as any)['id'];

                        const has = Object.keys(prop).length > 0;
                        return source && has && ((source as any)[InheritKey] = prop), source;
                    });

                    values.create(herits.filter(item => !!item));
                }

                const ret = buildRefInherits(this, values, key, reffield);
                return $$saving_.pop(), ret;
            },
            toJSON<Key extends TRefsKeys<TP>>(key: Key, obj: TP): JSON.Type {
                const { $json: { exclude }, $eprops_: { $$saving_ } } = obj;
                if (exclude[key]) return;

                const { $setting_: setting, $saving_: saving } = obj;

                if (!!(setting instanceof cls)) return;
                if (saving && $$saving_.level <= 1 && !obj.$changed_(key)) return;

                const values = refinherits.get!.call<TP, [Key], TP[Key]>(obj, key) as Array.ItemType<TP[Key]>[];
                let v: JSON.Type;
                if (values?.length > 0) {
                    $$saving_.push();

                    const subs = values.reduce((r, v) => {
                        const sub = v && JSON.JsonOf(v, {});
                        const valid = sub && (!(obj.$setting_ instanceof cls) || Object.keys(sub).length > 1);
                        return valid && r.push(sub), r;
                    }, <JSON.Type[]>[]);
                    subs && subs.length > 0 && (v = subs);

                    $$saving_.pop();
                }

                if (saving && !v) v = [];
                return v;
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                const { $setting_: setting } = obj;
                return !!(setting instanceof cls);
            }
        }

        const refflattens: _Editor.IHandle<TP> = {
            get<Key extends TRefsKeys<TP>>(this: TP, key: Key): TP[Key] | undefined {
                const { $props_: props, $setting_: setting, $eprops_: { $$saving_ } } = this;
                const reffer = cast<_Editor.RefFlattens<TP, Key, TSrc, TTree>>(settings);

                if (props[key]) return props[key];

                if (setting.$mayfail?.(key) && !setting[key]) {
                    // TODO: load setting failed from network liked, cannot initialize for next round trial.
                    //       may cause NULL array access exception, need further solution.
                    return;
                }

                // first-time, recursive to access the tree to flatten
                $$saving_.push();

                // init the flatten array to try.
                const items: Array.ItemType<TP[Key]>[] = (props[key] = [] as any);

                // TODO: invalid sources may encounter for it's setting load failed from network liked.
                //       may cause NULL array access exception, need further solution.
                const source = reffer?.children?.call(this, this);

                if (!source) {
                    // no children found, reset flatten arry for next round initialization
                    return delete props[key], $$saving_.pop(), undefined;
                }

                // recursively flatten each.
                const fetch = (items?: TTree[]) => {
                    items?.forEach(p => fetch(reffer?.children?.call(this, p)));
                }

                fetch(source);

                // build the create/delete react chain
                buildFlatten(this, items, key);

                $$saving_.pop();

                return props[key];
            },
            toJSON<Key extends TRefsKeys<TP>>(key: Key, obj: TP): JSON.Type {
                return;
            },
            isReadonly<Key extends TKeys<TP>>(key: Key, obj: TP): boolean {
                return true;
            }
        }

        function buildOwneds<
            Key extends TRefsKeys<TP>
        >(host: TP, source: Array.ItemType<TP[Key]>[], key: Key, asref: boolean): Array.ItemType<TP[Key]>[] {
            const { $eprops_: { $$context_: context, $$destroies_: destroies, $$saving_ } } = host;

            _Editor.$destroy_owneds<TP, Key>(host, key, { added: source });
            const membersowned: IMembersOwned<TP> = (context.membersowned = context.membersowned || {});
            membersowned[key] = source as any;

            destroies.push(_Editor.$destroy_subscription(source.onAddRemoved.subscribe(
                ({ added, removed }: {
                    removed: Array.ItemType<TP[Key]>[];
                    added: Array.ItemType<TP[Key]>[];
                }) => {
                    _Editor.$destroy_owneds<TP, Key>(host, key, { added, removed });
                }
            )))

            destroies.push(_Editor.$destroy_subscription(source.onPreAddRemove.subscribe(
                (current: Array.ItemType<TP[Key]>[]) => {
                    // in case of first time to change, record it for canceling.
                    if ($$saving_.level <= 0) {
                        const memberscancel: IMembersCancel<TP> = (context.memberscancel = context.memberscancel || {});

                        !memberscancel[key] && (
                            console.assert(memberscancel[key] == null),

                            memberscancel[key] = _Editor.owneds_cancel(
                                source, current.slice()
                            )
                        );
                    }
                }
            )))

            const { destroy } = source;
            !asref && Object.defineProperty(source, 'destroy', {
                enumerable: true, configurable: false,
                value: _Editor.array_destroy<TP>(host, key, destroy, function (this: Array.ItemType<TP[Key]>[], vals: Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                    });
                })
            })

            return source;
        }

        function buildRefInherits<
            Key extends TRefsKeys<TP>
        >(host: TP, source: Array<Array.ItemType<TP[Key]>>, key: Key, reffield: Array.ItemType<TP[Key]>[]): Array<Array.ItemType<TP[Key]>> {
            const { $eprops_: { $$context_: context, $$destroies_: destroies, $$saving_ } } = host;

            _Editor.$destroy_refinherits<TP, Key>(host, key, { added: source });

            reffield && destroies.push(_Editor.$destroy_subscription(reffield.onAddRemoved.subscribe(
                ({ added, removed }: {
                    removed: Array.ItemType<TP[Key]>[];
                    added: Array.ItemType<TP[Key]>[];
                }) => {
                    $$saving_.push();

                    removed && source.destroy(...removed.reduce((res, r) => {
                        const f = source.find(s => (s as any)?.['$base_'] == r);
                        return f && res.push(f), res;
                    }, <Array.ItemType<TP[Key]>[]>[]));

                    added && source.create(added);

                    $$saving_.pop();
                }
            )))

            destroies.push(_Editor.$destroy_subscription(source.onAddRemoved.subscribe(
                ({ added, removed }: {
                    removed: Array.ItemType<TP[Key]>[];
                    added: Array.ItemType<TP[Key]>[];
                }) => {
                    _Editor.$destroy_refinherits<TP, Key>(host, key, { added, removed });
                }
            )))

            destroies.push(_Editor.$destroy_subscription(merge(source.onPreAddRemove, source.onPreCreateDestroy).subscribe(
                (current: Array.ItemType<TP[Key]>[]) => {
                    // in case of first time to change, record it for canceling.
                    if ($$saving_.level <= 0) {
                        const memberscancel: IMembersCancel<TP> = (context.memberscancel = context.memberscancel || {});

                        !memberscancel[key] && (
                            console.assert(memberscancel[key] == null),
                            memberscancel[key] = _Editor.refinherits_cancel(
                                source, current.slice()
                            )
                        );
                    }
                }
            )))

            const { destroy } = source;
            Object.defineProperty(source, 'destroy', {
                enumerable: true, configurable: false,
                value: _Editor.array_destroy<TP>(host, key, destroy, function (this: Array.ItemType<TP[Key]>[], vals: Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                    });
                })
            })

            return source;
        }

        function buildFlatten<
            Key extends TRefsKeys<TP>
        >(host: TP, arr: Array.ItemType<TP[Key]>[], key: Key): Array.ItemType<TP[Key]>[] {
            const reffer = cast<_Editor.RefFlattens<TP, Key, TSrc, TTree>>(settings);
            const { createnew, destroy } = arr;

            Object.defineProperty(arr, 'createnew', {
                enumerable: true, configurable: false,
                value(this: Array.ItemType<TP[Key]>[], val: TSrc, at?: number): Array.ItemType<TP[Key]> | undefined {
                    if (!reffer?.creator) return undefined;

                    const tval = reffer.creator.call(host, val);
                    const newone = createnew.call(this, tval, at);
                    newone instanceof Editor && (
                        newone.$eprops_.$$context_.createnew = _Editor.refcreate_cancel(
                            this as Editor<any>[], newone
                        )
                    );

                    return newone;
                }
            })

            Object.defineProperty(arr, 'destroy', {
                enumerable: true, configurable: false,
                value: _Editor.array_destroy<TP>(host, key, destroy, function (this: Array.ItemType<TP[Key]>[], vals: Array.ItemType<TP[Key]>[]): void {
                    vals.forEach(v => {
                        _Editor.$destroy_(v);
                        reffer?.container?.call(host, v)?.destroy(v);
                    });
                })
            })

            return arr;
        }

        const handles: {
            [K in Type]?: _Editor.IHandle<TP>
        } = {
            [Type.guidid]: guidid,
            [Type.numberid]: numberid,
            [Type.owned]: owned,
            [Type.owneds]: owneds,
            [Type.inherit]: inherit,
            [Type.refowned]: refowned,
            [Type.refowneds]: refowneds,
            [Type.refinherit]: refinherit,
            [Type.refinherits]: refinherits,
            [Type.refparent]: refparent,
            [Type.refflattens]: refflattens,
        };

        return function (prototype: Object, fieldname: string | symbol): void {
            const jsonhandles = JSON.HandlerOf<TP>(prototype as any, true).handles!;
            const fieldkey = fieldname as TKeys<TP>;
            const bag = _FieldBag.Of(prototype, true);
            const handler = handles[editortype];
            const desc: PropertyDescriptor = {};

            (bag.prop = bag.prop || {})[fieldname] = {
                type: editortype, settings, handler
            }

            if (!handler) return;

            const { toJSON, get, set } = handler;
            if (toJSON && !_.isSymbol(fieldname)) {
                jsonhandles[fieldname] = (
                    (obj: TP): JSON.Type => (
                        toJSON(fieldname as TRefsKeys<TP>, obj)
                    )
                )
            }

            if (get) {
                desc.get = function (this: TP): any {
                    const val = get.call(this, fieldkey);
                    return this.$onvalue_?.(fieldkey, val, false), val;
                }
            }

            if (set) {
                desc.set = function (this: TP, value: any): void {
                    set.call(this, fieldkey, value);

                    get && this.$onvalue_?.(
                        fieldname, get.call(
                            this, fieldkey
                        ), !this.$saving_
                    );
                }
            }

            Object.defineProperty(prototype, fieldname, {
                configurable: false,
                enumerable: true,
                ...desc,
            })
        }
    }
}

export namespace Editor {
    export type IEnumItem = {
        value: null | number | string,
        title?: string,
        key: string,
    }

    export type IEnum = XArray<IEnumItem, IEnumItem, {
        [P: string | number]: IEnumItem
    }>;

    export const CreateIEnum = XArray.creator((item: IEnumItem, indexed: IEnum['indexed'], add: boolean): void => {
        const { key, value } = item;
        add ? (
            indexed[key] = item, value && (indexed[value] = item)
        ) : (
            delete indexed[key], value && (delete indexed[value])
        )
    })

    export interface IField<T extends Editor<any> = any> {
        source?: string | string[] | IEnum | ComponentType<any> | TemplateRef<any> | ((appservice: any, obj: T) => IEnum | undefined),
        invisible?: boolean | ((appservice: any, obj: T) => boolean),
        readonly?: boolean | ((appservice: any, obj: T) => boolean),
        dependfield?: IField,
        dependvalues?: any[],
        must?: boolean,
        type?: Value.Type,
        title?: string | ((appservice: any, obj?: T) => string),
        children?: string, // to indicate the children field name incase of type is a tree
        key?: string
    }

    export interface IFieldCol extends IField {
        cellcls?: string;
        col?: number;
        row?: number;
    }

    export interface IFields extends XArray<IField, IField, 'key'> {
    }

    export interface IFieldCols extends XArray<IFieldCol, IFieldCol, 'key'> {
    }

    export namespace Value {
        export enum Type {
            catmask = 0xFFFF0000,
            typemask = 0x0000FFFF,

            onlycolumn = 0x00010000,
            notcolumn = 0x00020000,
            select = 0x00040000,
            added = 0x00080000,
            multi = 0x00100000,
            details = 0x00200000,
            children = 0x00300000,

            pic = 1,
            bool = 2,       //  done: govform, govtable
            file = 3,
            date = 4,       //  done: govform, govtable
            text = 5,       //  done: govform, govtable
            number = 7,     //  done: govform, govtable
            enum = 8,
            json = 9,       //  done: govform, govtable
            percent = 10,   //  TBD: govform, govtable
            datenow = 11,   //  done: govform, govtable
            texts = 12,     //  done: govform

            email = 21,     //  done: govform
            phone = 22,     //  done: govform
            password = 23,  //  done: govform

            list = 41,
            tree = 42,
            option = 43,     //  done: govform, govtable

            command = 100,   //  done: govform
            problem = 101,   //  done: govform
            richtext = 102,

            label = 103,
            editor = 104,
            hint = 105,

            pics = 1048577,             //  multi | pic,     //  done: govform
            files = 1048579,            //  multi | file,    //  done: govform
            options = 1048619,          //  multi | option,  //  done: govform
            problems = 1048677,         //  multi | problem, //  done: govform
            rowselect = 851968,         //  select | added | onlycolumn,
            enums = 1048584             //  multi | enum,
        }

        export function isFieldType(header: IField | Type | undefined, type: (keyof typeof Type) | Type): boolean {
            const hdtype = _.isObject(header) ? header.type : header;
            if (hdtype === null || hdtype === undefined) return false;

            const typvalue: number = _.isString(type) ? Type[type] : type;
            const typcat = Type.catmask & typvalue, typval = Type.typemask & typvalue;
            const hdcat = Type.catmask & hdtype, hdval = Type.typemask & hdtype;
            if (typval == 0) return ((typcat & hdcat) == typcat);
            if (typcat == 0) return typval == hdval;

            return ((typcat & hdcat) == typcat) && (typval == hdval);
        }

        export function getFieldType(header?: IField | Type, catmask?: string | number): number {
            const hdtype = _.isObject(header) ? header.type : header;
            if (hdtype === null || hdtype === undefined) return 0;

            catmask = catmask ?? 0;
            const mask: number = (toEnum(catmask, Type) ?? 0) & Type.catmask;
            return (hdtype & Type.typemask) | (hdtype & mask);
        }

        const toValueHandles = {
            [Value.Type.bool]: {
                handle: toBool,
                type: Boolean
            },
            [Value.Type.date]: {
                handle: toDate,
                type: Date
            },
            [Value.Type.text]: {
                handle: toText,
                type: String
            },
            [Value.Type.number]: {
                handle: toNumber,
                type: Number
            },
            [Value.Type.json]: {
                handle: toJSON,
                type: JSON
            },
            [Value.Type.enum]: {
                handle: toEnum,
                type: Number
            }
        }

        const fromValueHandles = {
            [Value.Type.enum]: {
                handle: fromEnum,
                type: String
            }
        }

        function isNullUndefined(val: any): val is undefined | null {
            return val == null;
        }

        export function toBool(val: any): boolean {
            return _.isBoolean(val) ? val : (!isNullUndefined(val) ? Boolean(val) : !!val);
        }

        export function toDate(val?: string | Date | Moment): Date | undefined {
            return val instanceof Date ? val : (!isNullUndefined(val) ? (
                _.isString(val) || _.isNumber(val) ? new Date(val) : val?.toDate()
            ) : val);
        }

        export function toText(val: any): string | null | undefined {
            return _.isString(val) ? val : (!isNullUndefined(val) ? String(val) : val);
        }

        export function toNumber(val: any): number | null | undefined {
            return _.isNumber(val) ? val : (!isNullUndefined(val) ? Number(val) : val);
        }

        export function toJSON(val: any): JSON | string {
            if (!_.isString(val)) return val;

            try {
                return JSON.parse(val);
            } catch (e) { }

            return val;
        }

        function isIEnum(val: any): val is IEnum {
            // return !!Object.getOwnPropertyDescriptor(val, 'indexed');
            // return _.has(val, 'indexed');
            return !!val?.indexed
        }

        export type Enum<T = unknown> = {
            [id: string]: T | string;
            [nu: number]: string;
        }

        export function toEnum<TEnum extends IEnum>(val: string | number | undefined, aux: TEnum, valdef?: number): number | undefined;
        export function toEnum<TEnum extends Enum>(val: string | number | undefined, aux: TEnum, valdef?: TEnum): TEnum[keyof TEnum] | undefined;
        export function toEnum<TEnum extends IEnum | Enum>(val: string | number | undefined, aux: TEnum, valdef?: any): any {
            if (val == null) return;

            if (isIEnum(aux)) {
                const idxval = aux?.indexed?.[val];
                return _.isString((val = val ?? valdef)) ? (
                    (_.isObject(idxval) ? idxval.value : aux?.[val as keyof IEnum]) ?? valdef
                ) : val;
            }

            return _.isString(val) ? aux[val] : val;
        }

        export function fromEnum<TEnum extends IEnum>(val: string | number | undefined, aux: TEnum, valdef?: string): string | undefined;
        export function fromEnum<TEnum extends Enum>(val: string | number | undefined, aux: TEnum, valdef?: string): keyof TEnum | undefined;
        export function fromEnum<TEnum extends IEnum | Enum>(val: string | number | undefined, aux: TEnum, valdef?: string): string | undefined {
            if (val == null) return;

            if (isIEnum(aux)) {
                const idxval = aux?.indexed?.[val];
                return _.isNumber((val = val ?? valdef)) ? (
                    _.isObject(idxval) ? idxval.key : aux?.[val] as any
                ) : val;
            }

            return _.isNumber(val) ? aux[val] : val;
        }

        export function toValue(type: Value.Type, val: any, aux?: any, valdef?: any): any {
            type vhkeys = keyof typeof toValueHandles;
            const valtype = getFieldType(type) as vhkeys;
            const handle = toValueHandles[valtype];

            if (handle?.handle) {
                return handle.handle(val, aux, valdef);
            }

            return val;
        }

        export function fromValue(type: Value.Type, val: any, aux?: any, valdef?: any): any {
            type vhkeys = keyof typeof fromValueHandles;
            const valtype = getFieldType(type) as vhkeys
            const handle = fromValueHandles[valtype];

            if (handle?.handle) {
                return handle.handle(val, aux, valdef);
            }

            return val;
        }
    }
}

export namespace Editor {
    export enum Type {
        catmask = 0xFFFF0000,
        typemask = 0x0000FFFF,

        ref = 0x00010000,
        refs = 0x00020000,

        guidid = 0x00000001,
        numberid = 0x00000002,
        owned = 0x00000003,
        owneds = 0x00000004,
        inherit = 0x00000005,
        inherits = 0x00000006,  // not yet used
        parent = 0x00000007,    // not yet used
        flattens = 0x00000008,   // not yet used

        refowned = 0x00010003,
        refowneds = 0x00020004,
        refinherit = 0x00010005,
        refinherits = 0x00020006,
        refparent = 0x00010007,
        refflattens = 0x00020008,
    }

    export function Owned<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc = any
    >(key: Key, setting: {
        creator: RType<_Editor.Owned<TP, Key, TSrc>['creator']>,
        itemtype: (() => ClsType<RType<TP[Key]>>),
        getter?: (this: TP) => (() => TP[Key])
    }): _Editor.Owned<TP, Key, TSrc> {
        return { ...setting, key, type: Type.owned } as _Editor.Owned<TP, Key, TSrc>;
    }

    export function Owneds<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(key: Key, setting: {
        creator: RType<_Editor.Owneds<TP, Key>['creator']>
    }): _Editor.Owneds<TP, Key> {
        return { ...setting, key, type: Type.owneds } as _Editor.Owneds<TP, Key>
    }

    export function RefOwned<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc = TP[Key]
    >(key: Key, setting: {
        source: RType<_Editor.RefOwned<TP, Key, TSrc>['source']>,
        itemtype: () => ClsType<RType<TP[Key]>>,
        getter?: (this: TP) => (() => TP[Key])
    }): _Editor.RefOwned<TP, Key, TSrc> {
        return { ...setting, key, type: Type.refowned } as _Editor.RefOwned<TP, Key, TSrc>;
    }

    export function RefOwneds<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>, TSrc
    >(key: Key, setting: {
        source: RType<_Editor.RefOwneds<TP, Key, TSrc>['source']>,
        creator: RType<_Editor.RefOwneds<TP, Key, TSrc>['creator']>
    }): _Editor.RefOwneds<TP, Key, TSrc> {
        return { ...setting, key, type: Type.refowneds } as _Editor.RefOwneds<TP, Key, TSrc>
    }

    export function RefInherit<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>, TSrc = TP[Key]
    >(key: Key, setting: {
        source: RType<_Editor.RefInherit<TP, Key, TSrc>['source']>,
        creator: RType<_Editor.RefInherit<TP, Key, TSrc>['creator']>
    }): _Editor.RefInherit<TP, Key, TSrc> {
        return { ...setting, key, type: Type.refinherit } as _Editor.RefInherit<TP, Key, TSrc>;
    }

    export function RefInherits<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>, TSrc = Array.ItemType<TP[Key]>
    >(key: Key, setting: {
        source: RType<_Editor.RefInherits<TP, Key, TSrc>['source']>,
        creator: RType<_Editor.RefInherits<TP, Key, TSrc>['creator']>
    }): _Editor.RefInherits<TP, Key, TSrc> {
        return { ...setting, key, type: Type.refinherits } as _Editor.RefInherits<TP, Key, TSrc>;
    }

    export function RefParent<
        TP extends Editor<TP, EI<TP>>, Key extends TKeys<TP>
    >(key: Key, setting: {
        source: RType<_Editor.RefParent<TP, Key>['source']>,
        children: RType<_Editor.RefParent<TP, Key>['children']>,
        itemtype: () => ClsType<RType<TP[Key]>>,
        root?: (this: TP) => Exclude<TP[Key], null | undefined>
    }): _Editor.RefParent<TP, Key> {
        return { ...setting, key, type: Type.refparent } as _Editor.RefParent<TP, Key>;
    }

    // Recusively list all items without editable (create/delete)
    export function RefFlattens<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>, TTree
    >(key: Key, setting: {
        children: RType<_Editor.RefFlattens<TP, Key, never, TTree>['children']>
    }): _Editor.RefFlattens<TP, Key, never, TTree>;

    // Recusively list all items and can edit (create/delete)
    export function RefFlattens<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>, TSrc, TTree
    >(key: Key, setting: {
        creator: RType<_Editor.RefFlattens<TP, Key, TSrc, TTree>['creator']>,
        container: RType<_Editor.RefFlattens<TP, Key, TSrc, TTree>['container']>,
        children: RType<_Editor.RefFlattens<TP, Key, TSrc, TTree>['children']>
    }): _Editor.RefFlattens<TP, Key, TSrc, TTree>;

    export function RefFlattens<
        TP extends Editor<TP, EI<TP>>, Key extends TRefsKeys<TP>
    >(key: Key, setting: {}): _Editor.RefFlattens<TP, Key, any, any> {
        return { ...setting, key, type: Type.refflattens } as _Editor.RefFlattens<TP, Key, any, any>
    }
}

export namespace Editor {
    export type EI<T extends Editor<any, any>> = (
        T extends Editor<any, infer I> ? I : never
    );

    export type TKeys<T extends Editor<T, EI<T>>> = (
        Exclude<keyof T, keyof Editor.Editor<T, EI<T>>>
    );

    export type TBase<T extends Editor<T, EI<T>>> = (
        Pick<T, TKeys<T>>
    )

    export type TProp<T extends Editor<T, EI<T>>> = {
        -readonly [Key in TKeys<T>]?: T[Key]
    }

    export type TRefsKeys<T extends Editor<T, EI<T>>> = (
        {
            [Key in TKeys<T>]: Exclude<T[Key], undefined | null> extends ReadonlyArray<any> ? Key : never
        }[TKeys<T>]
    )

    export type TEntity = {
        cls?: ClsType<any>;
        refetch?: boolean;
        loaded?: boolean;
        section?: string;
        entity?: string;
        payload?: any;
    }

    export type TSettingHandler = {
        $mayfail?(key?: string | number | symbol): boolean;
        $create?(val: any, entity: TEntity): boolean | Observable<any>;
        $update?(val: any, entity: TEntity): boolean | Observable<any>;
        $delete?(val: any, entity: TEntity): boolean | Observable<any>;
        $entity?(val: any, property: string | number | symbol): TEntity | undefined;
        $entity?(val: any): TEntity | undefined;
    }

    export type TSetting<T extends Editor<T, EI<T>>> = (
        TProp<T> & TSettingHandler & {
            [InheritKey]?: TProp<T>,
            $inherits?: TProp<T>
        }
    )
}

namespace _Editor {
    export type EKeys<T extends Editor.Editor<T, EI<T>>> = (
        Extract<keyof T, keyof Editor.Editor<T, EI<T>>>
    )

    export type EBase<T extends Editor.Editor<T, EI<T>>> = (
        Editor.Editor<T>
    )

    export type EProp<T extends Editor.Editor<T, EI<T>>> = (
        {
            -readonly [Key in EKeys<T>]?: T[Key]
        } & {
            $inherits_?: Editor.TProp<T>,
            $setting_: Editor.TSetting<T>,
            $$destroies_: IDestroies<T>,
            $$context_: IContext<T>,
            $$saving_: {
                readonly level: number,
                push(): void,
                pop(): void,
            }
        }
    )
}

namespace _Editor {
    export interface IHandle<T extends Editor.Editor<T, EI<T>>> {
        isReadonly?<Key extends Editor.TKeys<T>>(key: Key, obj: T): boolean;
        isReadonly?<Key extends Editor.TRefsKeys<T>>(key: Key, obj: T): boolean;

        toJSON?<Key extends Editor.TRefsKeys<T>>(key: Key, obj: T): JSON.Type;
        toJSON?<Key extends Editor.TKeys<T>>(key: Key, obj: T): JSON.Type;

        set?<Key extends Editor.TKeys<T>>(this: T, key: Key, value: T[Key]): void;

        prevalget?<Key extends Editor.TRefsKeys<T>>(this: T, key: Key): T[Key];
        prevalget?<Key extends Editor.TKeys<T>>(this: T, key: Key): T[Key];

        get?<Key extends Editor.TRefsKeys<T>>(this: T, key: Key): T[Key];
        get?<Key extends Editor.TKeys<T>>(this: T, key: Key): T[Key];
    }

    declare const $type: unique symbol;
    export type TypeSetting<TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TType extends Editor.Type, TSet extends {} = {}> = A.Compute<{
        [$type]: TP, type: TType, key: Key,
    } & TSet, 'flat'>

    export type Owned<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TSrc = any
    > = TypeSetting<TP, Key, Editor.Type.owned, {
        creator?: (this: TP, item: TSrc) => TP[Key],
        isReadonly?: boolean | (() => boolean),
        getter?: (this: TP) => (() => TP[Key]),
        itemtype?: () => Type<TP[Key]>
    }>

    export type Owneds<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>
    > = TypeSetting<TP, Key, Editor.Type.owneds, {
        creator?: (this: TP) => TP[Key]
    }>

    export type RefOwned<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TSrc = TP[Key]
    > = TypeSetting<TP, Key, Editor.Type.refowned, {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc | undefined } | undefined,
        getter?: (this: TP) => (() => TP[Key]),
        itemtype?: () => Type<TP[Key]>
    }>

    export type RefOwneds<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc = Array.ItemType<TP[Key]>
    > = TypeSetting<TP, Key, Editor.Type.refowneds, {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc | undefined } | undefined,
        creator?: (this: TP) => TP[Key]
    }>

    export type RefInherit<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>, TSrc = TP[Key]
    > = TypeSetting<TP, Key, Editor.Type.refinherit, {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc | undefined } | undefined,
        creator?: (this: TP, item: TSrc) => TP[Key]
    }>

    export type RefInherits<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc = Array.ItemType<TP[Key]>
    > = TypeSetting<TP, Key, Editor.Type.refinherits, {
        source?: (this: TP) => { firstOf(by: { id: any }): TSrc | undefined } | undefined,
        creator?: (this: TP) => TP[Key]
    }>

    export type RefParent<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TKeys<TP>
    > = TypeSetting<TP, Key, Editor.Type.refparent, {
        source?: (this: TP) => { firstOf(by: { id: any }): RType<TP[Key]> | undefined } | undefined,
        children?: (this: TP, parent?: RType<TP[Key]>) => TP[] | undefined,
        root?: (this: TP) => RType<TP[Key]>,
        itemtype?: () => Type<RType<TP[Key]>>
    }>

    export type RefFlattens<
        TP extends Editor.Editor<TP, EI<TP>>, Key extends Editor.TRefsKeys<TP>, TSrc = Array.ItemType<TP[Key]>, TTree = Array.ItemType<TP[Key]>
    > = TypeSetting<TP, Key, Editor.Type.refflattens, {
        container?: ((this: TP, obj: Array.ItemType<TP[Key]>) => Array.ItemType<TP[Key]>[] | undefined),
        creator?: ((this: TP, item: TSrc) => Array.ItemType<TP[Key]>),
        children?: (this: TP, obj: TP | TTree) => TTree[] | undefined
    }>
}

namespace _Editor {
    export function array_destroy<
        T extends Editor.Editor<T, EI<T>>, Key extends string | number | symbol = Editor.TRefsKeys<T>
    >(host: T, property: Key, destroy: (...val: any[]) => any, proc?: (this: any, vals: any[]) => void) {
        const { $eprops_: { $$saving_ } } = host;

        return function <Key extends Editor.TRefsKeys<T>>(
            this: Array.ItemType<T[Key]>[],
            ...vals: (Array.ItemType<T[Key]> | undefined)[]
        ): Array.ItemType<T[Key]>[] | Observable<Array.ItemType<T[Key]>[]> {
            if ($$saving_.level <= 1) {
                const entity = Editor.detect.cud?.$entity?.(host, property);
                let rets = entity ? Editor.detect.cud?.$delete?.(
                    vals.map(
                        v => (v as any)?.["id"]
                    ).filter(
                        v => !!v
                    ),
                    entity
                ) : true;

                if (rets instanceof Observable) {
                    $$saving_.push();

                    const sub = (rets = rets.pipe(
                        finalize(() => {
                            tick(() => sub?.unsubscribe());
                        })
                    )).subscribe({
                        next: (value: any): void => {
                            proc?.call(this, vals);
                            const _val = destroy.apply(this, vals);
                            $$saving_.pop();
                            return _val;
                        },
                        error: (error: any): void => {
                            $$saving_.pop();
                        }
                    })

                    return rets;
                }
            }

            proc?.call(this, vals);
            const _val = destroy.apply(this, vals);
            return _val;
        }
    }
}

namespace _Editor {

    export function ownedchanged(owneds?: Editor.Editor<any> | Array<Editor.Editor<any>>): boolean {
        return (_.isArray(owneds) ? owneds : [owneds]).some(o => o instanceof Editor.Editor && o.$changed_());
    }

    export function ownedcancel(owneds?: Editor.Editor<any> | Array<Editor.Editor<any>>): void {
        (_.isArray(owneds) ? owneds : [owneds]).forEach(o => o instanceof Editor.Editor && o.$cancel_());
    }

    export function ownedsave(owneds?: Editor.Editor<any> | Array<Editor.Editor<any>>): void {
        (_.isArray(owneds) ? owneds : [owneds]).forEach(o => o instanceof Editor.Editor && o.$save_({ drilling: true }));
    }

    export function ownedupdate(owneds: Editor.Editor<any> | Array<Editor.Editor<any>> | undefined, value: any): void {
        if (_.isArray(owneds)) {
            return owneds.forEach((o, idx) => o instanceof Editor.Editor && o.$update_(value?.[idx], true));
        }

        owneds instanceof Editor.Editor && owneds.$update_(value, true);
    }

    export function ownedsaving(owneds: Editor.Editor<any> | Array<Editor.Editor<any>> | undefined, saving: boolean): void {
        (_.isArray(owneds) ? owneds : [owneds]).forEach(
            o => o instanceof Editor.Editor && (
                saving ? o.$eprops_.$$saving_.push() : o.$eprops_.$$saving_.pop()
            )
        );
    }

    export function owneds_cancel<T>(arr: T[], pres: T[]): IOP {
        return _.extend(function () {
            arr.reset(...pres);
        }, {
            preval: pres,
        })
    }

    export function refcreate_cancel<T extends Editor.Editor<any>>(arr: T[], elm: T) {
        return function () {
            $destroy_(elm), arr.destroy(elm);
        }
    }

    export function refinherits_cancel<T>(arr: T[], pres: T[]): IOP {
        return _.extend(function () {
            // 1. destroy elements from arr which not in pres.
            // 2. keep elements in pres but not in arr, and notify create.

            const todestroy = arr.filter((a: any) => (
                pres.findIndex((p: any) => (p['$base_'] || p) == (a['$base_'] || a)) < 0
            ))

            const tocreate = pres.filter((p: any) => (
                arr.findIndex((a: any) => (a['$base_'] || a) == (p['$base_'] || p)) < 0
            ))

            arr.destroy(...todestroy);

            const hasc = tocreate.length > 0;

            arr.add(tocreate);

            hasc && arr.events.createDestroied?.emit({ created: tocreate });
            hasc && arr._onCreateDestroied?.(tocreate);
        }, {
            preval: pres
        })
    }

    export function $destroy_(obj: any) {
        obj?.['$destroy_']?.();
    }

    export function $destroy_cancel(obj: any) {
        return function () {
            $destroy_(obj);
        }
    }

    export function $destroy_subscription(subscription: Subscription) {
        return function () {
            subscription.unsubscribe();
        }
    }

    export function $destroy_ref<
        T extends Editor.Editor<any>
    >(host: T, src: T) {
        const { $eprops_: { $$destroies_ } } = host;
        const key = '$destroy_';

        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback(obj?: object) {
                src?.$eprops_.$$destroies_?.remove(cb.callback);
                if (obj != host) $destroy_(host);
            }
        }));

        (src as Editor.Editor<any>)?.$eprops_.$$destroies_?.push(cb.callback);
    }

    export function $destroy_refowned<
        T extends Editor.Editor<T, EI<T>>, Key extends Editor.TKeys<T>
    >(host: T, key: Key, src: T[Key]) {
        const { $eprops_: { $$destroies_ } } = host;

        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback: _.extend(function (obj?: object) {
                const _src = (obj == host ? host[key] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == host[key]);
                host[key] = undefined as any;
            }, {
                preval: src
            })
        }));

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src.$eprops_.$$destroies_?.push(cb.callback);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_refparent<
        T extends Editor.Editor<T, EI<T>>, Key extends Editor.TKeys<T>
    >(host: T, key: Key, src: T[Key] | undefined, reffer?: RefParent<T, Key>) {
        function set(_host: T, _preparent?: RType<T[Key]>, _parent?: RType<T[Key]>) {
            if (_preparent == _parent) return;

            const oldcoll = reffer?.children?.call(_host, _preparent);
            const newcoll = reffer?.children?.call(_host, _parent);

            // try to remove from previous parent
            oldcoll?.remove(_host);

            // try to insert into new parent
            !newcoll?.has(_host) && newcoll?.unshift(_host);
        }

        const { $eprops_: { $$destroies_ } } = host;
        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback: _.extend(function (obj?: object) {
                const _src = (obj == host ? host[key] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == host[key]);
                host[key] = undefined as any;
            }, {
                preval: src
            })
        }));

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src?.$eprops_.$$destroies_?.push(cb.callback);
        set(host, cb.data, src as any);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_refinherit<
        T extends Editor.Editor<T, EI<T>>, Key extends Editor.TKeys<T>
    >(host: T, key: Key, src: T[Key], reffer?: RefInherit<T, Key>) {
        function set(_host: T, _preobj: T[Key], _obj: T[Key]) {
            if (_preobj == _obj) return;
            const { $props_: props } = host;

            console.assert((props[key] as any)?.['$base_'] == _preobj);
            $destroy_(props[key]);
        }

        const { $eprops_: { $$destroies_ } } = host;
        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback: _.extend(function (obj?: object) {
                const { $props_: props } = host, _src = (obj == host ? (props[key] as any)?.['$base_'] : obj);

                (_src instanceof Editor.Editor) && _src?.$eprops_.$$destroies_?.remove(cb.callback);
                console.assert(_src == (props[key] as any)?.['$base_']);
                host[key] = undefined as any;
            }, {
                preval: src
            })
        }))

        if (cb.data == src) return cb;

        (cb.data instanceof Editor.Editor) && cb.data.$eprops_.$$destroies_?.remove(cb.callback);
        (src instanceof Editor.Editor) && src.$eprops_.$$destroies_?.push(cb.callback);
        set(host, cb.data, src);

        cb.callback.preval = src;
        return cb.data = src, cb;
    }

    export function $destroy_owneds<
        T extends Editor.Editor<T, EI<T>>, Key extends Editor.TRefsKeys<T>
    >(host: T, key: Key, { added, removed }: {
        removed?: Array.ItemType<T[Key]>[],
        added?: Array.ItemType<T[Key]>[],
    }) {

        const { $eprops_: { $$destroies_ } } = host;
        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback(obj?: object) {
                const values: Array.ItemType<T[Key]>[] = host[key] as any;

                if (obj == host) {
                    values?.forEach((r: any) => {
                        (r instanceof Editor.Editor) && r.$eprops_.$$destroies_?.remove(cb.callback);
                    });

                    values?.recreate();
                    return;
                }

                (obj instanceof Editor.Editor) && obj.$eprops_.$$destroies_?.remove(cb.callback);
                values?.remove(obj as Array.ItemType<T[Key]>);
            }
        }));

        removed?.forEach((r: any) => {
            (r instanceof Editor.Editor) && r.$eprops_.$$destroies_?.remove(cb.callback);
        })

        added?.forEach((a: any) => {
            (a instanceof Editor.Editor) && a.$eprops_.$$destroies_?.push(cb.callback);
        })

        return cb;
    }

    export function $destroy_refinherits<
        T extends Editor.Editor<T, EI<T>>, Key extends Editor.TRefsKeys<T>
    >(host: T, key: Key, { added, removed }: {
        removed?: Array.ItemType<T[Key]>[],
        added?: Array.ItemType<T[Key]>[],
    }) {
        const { $eprops_: { $$destroies_ } } = host;
        const cb = ($$destroies_.host[key] || ($$destroies_.host[key] = {
            callback(obj?: object) {
                const values: Array.ItemType<T[Key]>[] = host[key] as any;

                if (obj == host) {
                    values?.forEach((r: any) => {
                        const $base = r?.['$base_'];
                        ($base instanceof Editor.Editor) && $base.$eprops_.$$destroies_?.remove(cb.callback);
                    });

                    values?.recreate();
                    return;
                }

                (obj instanceof Editor.Editor) && obj.$eprops_.$$destroies_?.remove(cb.callback);
                const value = values.find((v: any) => v?.['$base_'] == obj);
                value && values?.destroy(value);
            }
        }));

        removed?.forEach((r: any) => {
            const $base = r?.['$base_'];
            ($base instanceof Editor.Editor) && $base.$eprops_.$$destroies_?.remove(cb.callback);
        })

        added?.forEach((a: any) => {
            const $base = a?.['$base_'];
            ($base instanceof Editor.Editor) && $base.$eprops_.$$destroies_?.push(cb.callback);
        })

        return cb;
    }
}
