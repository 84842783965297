<ng-template id="label" let-formGroup="formGroup" let-schema="schema" let-field="field">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="d-none" matInput />
        <label>{{field.value | translate}}</label>
    </mat-form-field>
</ng-template>

<ng-template id="text" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="px-1 py-0 my-1 max-vh-10" [formControl]="control" [readonly]="control.readonly"
            [(ngModel)]="field.field.value" [required]="!schema.optional" cdkTextareaAutosize matInput />

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="texts" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <textarea class="px-1 py-0 my-1 max-vh-10" [formControl]="control" [readonly]="control.readonly"
            [(ngModel)]="field.field.value" [required]="!schema.optional" cdkTextareaAutosize matInput></textarea>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="email" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input type="email" class="px-1 py-0 my-1 max-vh-10" [formControl]="control" [readonly]="control.readonly"
            [(ngModel)]="field.field.value" [required]="!schema.optional" cdkTextareaAutosize matInput />

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="password" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input type="password" class="px-1 py-0 my-1 max-vh-10" [formControl]="control" [readonly]="control.readonly"
            [(ngModel)]="field.field.value" [required]="!schema.optional" cdkTextareaAutosize matInput />

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="number" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="px-1 py-0 my-1 max-vh-10" type="number" autocomplete="off" [formControl]="control"
            [(ngModel)]="field.field.value" [readonly]="control.readonly" [required]="!schema.optional" matInput />

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="phone" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="px-1 py-0 my-1 max-vh-10" type="tel" autocomplete="off" [formControl]="control"
            [(ngModel)]="field.field.value" [readonly]="control.readonly" [required]="!schema.optional" matInput />

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="bool" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name | translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="d-none" matInput [formControl]="control" [required]="!schema.optional"
            [readonly]="control.readonly" />

        <div puEventStop="click" [stopPropagation]="false" [stopDefault]="control.readonly"
            class="px-1 py-0 my-1 toggle toggle--knob">
            <input type="checkbox" class="toggle--checkbox" id="field-form-toggle--{{ schema.key }}"
                [formControl]="control" [(ngModel)]="field.field.value" [readonly]="control.readonly"
                [required]="!schema.optional" (change)="schema.onchange?.(record, field, field.fieldname)" />

            <label class="m-auto toggle--btn" for="field-form-toggle--{{ schema.key }}">
                <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                    [attr.data-label-on]="'general.yes' | translate"></span>
            </label>
        </div>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="date" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name| translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="px-1 py-0 my-1" autocomplete="off" [matDatepicker]="picker" [formControl]="control"
            [(ngModel)]="field.field.value" [readonly]="control.readonly" [required]="!schema.optional" matInput />

        <mat-datepicker-toggle [disabled]="control.disabled" [for]="picker" matSuffix></mat-datepicker-toggle>

        <mat-datepicker [disabled]="control.disabled" #picker></mat-datepicker>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="json" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name| translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input matInput class="d-none" [formControl]="control" [required]="!schema.optional"
            [readonly]="control.readonly" />

        <json-editor #jsoneditor (change)="field.field.value = jsoneditor.get()" [options]="jsoneditoroptions"
            [data]="field.field.value"></json-editor>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="option" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label #searcher>
            {{ schema.name + (!control.readonly && ("general.select" | translate) || '') }}
        </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <mat-select class="px-1 py-0 my-1" panelClass="searchable-panel" [formControl]="control"
            [(ngModel)]="field.field.value" [disabled]="control.disabled" [required]="!schema.optional">

            <ng-template #option let-opt="opt" let-filter="filter">
                <mat-option [value]="opt.id" [disabled]="control.disabled" [class.d-none]="
                      (field.field.value == opt.id) ||
                      (filter.value && !field.option.value(opt)?.includes(
                        filter.value
                      ))
                    ">
                    {{ field.option.value(opt) }}
                </mat-option>
            </ng-template>

            <div class="min-vh-20 w-100 overflow-auto">
                <ng-container *ngFor="let opt of field.option.source" [ngTemplateOutlet]="option"
                    [ngTemplateOutletContext]="{
                        filter: fieldersearcher.getFielder(searcher).field,
                        opt: opt
                    }"></ng-container>
            </div>

            <mat-option class="dropdown-menu-panel row-cols-1 searcher">
                <mat-divider [inset]="true" class="w-100 m-0 mb-2"></mat-divider>
                <div class="d-flex flex-row flex-nowrap w-100" puEventStop="click" [stopDefault]="false">
                    <input placeholder="{{ 'general.searchhint' | translate }}" [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="fieldersearcher.getFielder(searcher).field.value" class="col w-0 px-2" />
                    <button *ngIf="field.option.source.management && managable && !(control.readonly)"
                        (click)="field.option.source.management()" mat-raised-button class="col-auto" color="primary">
                        {{ ("general.management" | translate).format({ entity: schema.name })
                    }}
                    </button>
                </div>
            </mat-option>
        </mat-select>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="options" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label #searcher>
            {{ schema.name + (!control.readonly && ("general.select" | translate) || '') }}
        </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <mat-select class="px-1 py-0 my-1" panelClass="searchable-panel" [formControl]="control"
            [(ngModel)]="field.field.value" [disabled]="control.disabled" [multiple]="true"
            [required]="!schema.optional" [compareWith]="optionsCompareWith">

            <ng-template #option let-opt="opt" let-filter="filter">
                <mat-option [value]="opt" [disabled]="control.disabled" [class.d-none]="
                    (filter.value && !field.option.value(opt)?.includes(
                        filter.value
                    ))
                ">
                    {{ field.option.value(opt) }}
                </mat-option>
            </ng-template>

            <div class="min-vh-20 w-100 overflow-auto">
                <ng-container *ngFor="let opt of field.option.source" [ngTemplateOutlet]="option"
                    [ngTemplateOutletContext]="{
                        filter: fieldersearcher.getFielder(searcher).field,
                        opt: opt
                    }"></ng-container>
            </div>

            <mat-option class="dropdown-menu-panel row-cols-1 searcher">
                <mat-divider [inset]="true" class="w-100 m-0 mb-2"></mat-divider>
                <div class="d-flex flex-row flex-nowrap w-100" puEventStop="click" [stopDefault]="false">
                    <input placeholder="{{ 'general.searchhint' | translate }}" [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="fieldersearcher.getFielder(searcher).field.value" class="col w-0 px-2" />
                    <button *ngIf="field.option.source.management && managable && !(control.readonly)"
                        (click)="field.option.source.management()" mat-raised-button class="col-auto" color="primary">
                        {{ ("general.management" | translate).format({ entity: schema.name }) }}
                    </button>
                </div>
            </mat-option>
        </mat-select>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="select" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">

    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label #searcher>
            {{ schema.name + (!control.readonly && ("general.select" | translate) || '') }}
        </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <mat-select class="px-1 py-0 my-1" panelClass="searchable-panel" [disabled]="control.disabled"
            [formControl]="control" [(ngModel)]="field.field.value" [required]="!schema.optional">

            <ng-template #option let-opt="opt" let-filter="filter" let-selected="selected" let-value="value">
                <mat-option #item="matOption" [disabled]="control.disabled" [value]="value" (puViewChecked)="
                    (item.value != value || item.selected != selected) && tick(
                        [_.set, undefined, item, 'value', value], 
                        [item[selected ? 'select' : 'deselect'], item, true]
                    )" [class.d-none]="
                    selected || (
                        filter.value && !field.option.value(opt)?.includes(
                            filter.value
                        )
                    )
                ">
                    {{ field.option.value(opt) }}
                </mat-option>
            </ng-template>

            <div class="min-vh-20 w-100 overflow-auto">
                <ng-container *ngFor="let opt of field.option.source">
                    <ng-container *ngTemplateOutlet="option; context: {
                        selected: ((field.field.value?.value?.key || field.field.value) == opt.key),
                        value: optionOf(field, opt, schema),
                        filter: fieldersearcher.getFielder(searcher).field,
                        opt: opt
                    }"></ng-container>
                </ng-container>
            </div>

            <mat-option class="row-cols-1 searcher">
                <mat-divider [inset]="true" class="w-100 m-0 mb-2"></mat-divider>
                <div class="d-flex flex-row flex-nowrap w-100" puEventStop="click" [stopDefault]="false">
                    <input placeholder="{{ 'general.searchhint' | translate }}" [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="fieldersearcher.getFielder(searcher).field.value" class="col w-0 px-2" />
                    <button *ngIf="field.option.source.management && managable && !(control.readonly)"
                        (click)="field.option.source.management()" mat-raised-button class="col-auto" color="primary">
                        {{ ("general.management" | translate).format({ entity: schema.name }) }}
                    </button>
                </div>
            </mat-option>

        </mat-select>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template id="tree" let-formGroup="formGroup" let-schema="schema" let-field="field" let-control="control">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100 pb-2">
        <mat-label>{{ schema.name| translate }} </mat-label>
        <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>

        <input class="d-none" matInput [formControl]="control" [required]="!schema.optional"
            [readonly]="control.readonly" />

        <mat-tree class="px-1 py-0 my-1 pu-tree" style="overflow-x: auto; overflow-y: hidden; white-space: nowrap;"
            #treesetting="PuNestedTree" [dataSource]="treesetting.dataSource" [treeControl]="treesetting.control"
            [pu-nested-tree]="{
                formControl: control,
                source: field.option.source,
                field: field.field,
                schema: schema,
                leafonly: true
            }">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <mat-checkbox class="checklist-leaf-node" [checked]="treesetting.isselected(node)"
                    [disabled]="control.disabled"
                    (change)="$event.checked ? treesetting.select(node) : treesetting.deselect(node)">
                    {{ _.get(node, field.path.ipath || 'title') || (node.name | translate) }}
                </mat-checkbox>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: treesetting.whenChecker" matTreeNodePadding>
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treesetting.control.isExpanded(node) ? "expand_more" : "chevron_right" }}
                        </mat-icon>
                    </button>

                    <mat-checkbox [disabled]="control.disabled" [checked]="treesetting.isselected(node)"
                        [indeterminate]="treesetting.isindeterminate(node)" (change)="
                        $event.checked ? treesetting.select(node) : treesetting.deselect(node)
                    ">
                        {{ _.get(node, field.path.ipath || 'title') || (node.name | translate) }}
                    </mat-checkbox>
                </div>

                <div [class.d-none]="!treesetting.control.isExpanded(node)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>

        <mat-hint *ngIf="schema.hint" class="text-wrap"><span class="w-100">{{ schema.hint }}</span></mat-hint>

        <mat-error *ngIf="control.hasError('value')" class="text-wrap">
            {{ control.getError('value') | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template #expandschema let-formGroup="formGroup" let-fieldpath="fieldpath" let-compact="compact" let-schema="schema"
    let-value="value">
    <ng-template #expandfield let-field="field">
        <ng-template #expandfld let-item="item">
            @if (true) {
            @let context = {fieldpath: field.fieldpath, formGroup, value: field.host, compact: true, schema: item};
            <ng-container *ngTemplateOutlet="expandschema; context: context"></ng-container>
            }
        </ng-template>

        <ng-template #expanddep let-item="item">
            @if (true) {
            @let value = initdeps(field, item);
            @let fieldpath = field.fieldpath + '.deps.' + item.key;
            @let context = {fieldpath, value, formGroup, compact: false, schema: item};
            <ng-container *ngTemplateOutlet="expandschema; context: context"></ng-container>
            }
        </ng-template>

        <ng-template #expandsubs let-template="template" let-subs="subs">
            @for (sub of subs || []; track $index) {
            @switch (_.isArray(sub) ? 'group' : '') {
            @case ('group') {
            <div class="w-100 m-0 p-0 d-flex flex-row flex-wrap justify-content-between align-items-start">
                @for (ssub of sub; track $index) {
                <div class="p-0 m-0 border-0" [style.flex-grow]="ssub.size">
                    <ng-container *ngTemplateOutlet="template; context: { item: ssub}"></ng-container>
                </div>
                }
            </div>
            }

            @default {
            <ng-container *ngTemplateOutlet="template; context: { item: sub}"></ng-container>
            }
            }
            }
        </ng-template>

        <!-- 1. build type: need conside whether in flds for compat mode-->
        @switch (typeOf(schema)) {
        @case ('select') {
        @if (!isInvisible(field)) {
        @let control = controlOf(field, schema, formGroup);
        @let context = {control, formGroup, schema, field};
        <ng-container *puTemplateOutlet="'select'; context: context"></ng-container>
        }

        <!-- whether has selected the options -->
        @let key = (field.field.value?.value?.key || field.field.value);
        @let _schema = schema.type.indexed[key];
        @if (_schema) {
        @let fieldpath = field.fieldpath + '.value';
        @let value = (field.compact ? field.field.value : field.host).value;
        @let context = {schema: _schema, value, fieldpath, formGroup, compact: false};
        <ng-container *ngTemplateOutlet="expandschema; context: context"></ng-container>
        }
        }

        @case ('options') {
        @if (!isInvisible(field)) {
        @let control = controlOf(field, schema, formGroup);
        @let context = {control, formGroup, schema, field};
        <ng-container *puTemplateOutlet="'options'; context: context"></ng-container>
        }
        }

        @case ('option') {
        @if (!isInvisible(field)) {
        @let control = controlOf(field, schema, formGroup);
        @let context = {control, formGroup, schema, field};
        <ng-container *puTemplateOutlet="'option'; context: context"></ng-container>
        }
        }

        @case ('tree') {
        @if (!isInvisible(field)) {
        @let control = controlOf(field, schema, formGroup);
        @let context = {control, formGroup, schema, field};
        <ng-container *puTemplateOutlet="'tree'; context: context"></ng-container>
        }
        }

        @default {
        @if (!isInvisible(field)) {
        @let control = controlOf(field, schema, formGroup);
        @let context = {control, formGroup, schema, field};
        <ng-container *puTemplateOutlet="schema.type; context: context"></ng-container>
        }
        }
        }

        <!-- 2. build deps -->
        <ng-container [ngTemplateOutletContext]="{template: expanddep, subs: schema.deps}"
            [ngTemplateOutlet]="expandsubs"></ng-container>

        <!-- 3. build flds -->
        <ng-container [ngTemplateOutletContext]="{template: expandfld, subs: schema.flds}"
            [ngTemplateOutlet]="expandsubs"></ng-container>
    </ng-template>

    <ng-template #flatten>
        @if (true) {
        @let stage = {fieldpath, compact};
        @let field = fieldOf(value, schema, stage);
        <ng-container *ngTemplateOutlet="expandfield; context: {field}"></ng-container>
        }
    </ng-template>

    @switch (schema.section ? 'section' : '') {
    @case ('section') {
    <div ngbAccordion class="py-2">
        <div ngbAccordionItem [collapsed]="false" class="bg-light ">
            <div ngbAccordionHeader class="">
                <button ngbAccordionButton>{{ schema.section }}<b></b></button>
            </div>
            <div ngbAccordionCollapse>
                <div class="w-100 m-0 p-0 d-flex flex-row flex-wrap justify-content-between align-items-start"
                    ngbAccordionBody>
                    <ng-container *ngTemplateOutlet="flatten">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    }

    @default {
    <ng-container *ngTemplateOutlet="flatten">
    </ng-container>
    }
    }
</ng-template>

@if (formOf(record)) {
<form class="w-100 m-0 p-0 d-flex flex-column flex-nowrap align-items-stretch g-untouched ng-pristine ng-valid"
    [formGroup]="formOf(record)!" autocomplete="off">
    @let formGroup = formOf(record);
    @let context = {formGroup, schema, compact: false, fieldpath: '', value: record};
    <ng-container *ngTemplateOutlet="expandschema; context: context"></ng-container>
</form>
}