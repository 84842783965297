<ng-template id="sumpatchproject" let-toolbar="toolbar">
    <sumpatchproject [toolbar]="toolbar"></sumpatchproject>
</ng-template>

<ng-template id="sumongoingproject" let-toolbar="toolbar" let-module="module">
    <sumongoingproject [toolbar]="toolbar" [module]="module"></sumongoingproject>
</ng-template>

<ng-template id="sumprojectstage" let-toolbar="toolbar">
    <sumprojectstage [toolbar]="toolbar"></sumprojectstage>
</ng-template>

<ng-template id="sumprojectworkitem" let-toolbar="toolbar">
    <sumprojectworkitem [toolbar]="toolbar"></sumprojectworkitem>
</ng-template>

<ng-template id="sumcancelproject" let-toolbar="toolbar">
    <sumcancelproject [toolbar]="toolbar"></sumcancelproject>
</ng-template>

<div class="app-gov-editor h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
    <div class="headersplitter d-flex flex-row justify-content-between align-items-stretch flex-none">
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        <nav class="overflow-auto d-flex align-items-end flex-row flex-nowrap flex-fill" mat-tab-nav-bar
            [tabPanel]="tabPanel" [mat-stretch-tabs]="false">
            @for (module of modules; track module.$cid; let idx = $index) {
            <a mat-tab-link class="tabbox tabheader" [active]="modules.currentIndex == idx"
                (click)="modules.currentIndex = idx">
                <mat-icon>{{ module.dict?.icon }}</mat-icon>
                {{ (module.dict?.title ?? '') | translate }}
            </a>
            }
        </nav>
        <gov-toolbar #govtoolbar="GovToolbar" class="flex-grow-0 flex-shrink-0"></gov-toolbar>
    </div>

    @if (currentmodule.template != null) {
    <div class="overflow-auto flex-fill">
        @let context = {toolbar: govtoolbar, module: currentmodule};
        <ng-container *puTemplateOutlet="currentmodule.template; context: context"></ng-container>
    </div>
    } @else {
    <gov-splitter class="min-h-5 d-flex flex-row align-items-stretch justify-content-start flex-fill"
        [direction]="'horizontal'">
        <gov-table gov-splitter-panel #govtablemodel="GovTable" class="overflow-hidden h-100"
            (onCommandTemplate)="onCommandTemplate.emit($event)" (onWorkItemDetailsTemplate)="onWorkItemDetails($event)"
            (onDiagramImported)="onDiagramImported.emit($event)" (onDiagramDestroied)="onDiagramDestroied.emit($event)"
            [size]="noform ? 100 : 70" [datasets]="currentmodule" [toeditting]="toeditting"
            [nopaginator]="currentmodule.nopaginator" [toolbar]="govtoolbar">
        </gov-table>

        @if (!noform) {
        <gov-form gov-splitter-panel class="overflow-hidden h-100" (onCommandTemplate)="onCommandTemplate.emit($event)"
            [readonly]="!govtablemodel?.editable" [datasets]="currentmodule" [size]="30" [max]="80" [min]="20">
        </gov-form>
        }
    </gov-splitter>
    }
</div>