import type { PartialDeep } from 'type-fest';

import { Editor } from '../../../utils/libs/editor';
import { Org as TOrg, App as TApp } from './types';
import { Backface } from './config';

export class Org extends Editor.Editor<Org, TOrg.IOrg> implements TOrg.IOrg {
    @JSON.Key()
    @Backface.Entity(() => Org.Priviledge, "org")
    @Editor.Field(Org, Editor.Owned('priviledge', {
        itemtype: () => Org.Priviledge,
        creator(this: Org, item: TOrg.IPriviledge) {
            return new Org.Priviledge(this, undefined, item)
        },
    })) readonly priviledge!: Org.Priviledge;

    @JSON.Key()
    @Backface.Entity(() => Org.Dept, "org")
    @Editor.Field(Org, Editor.Owned('dept', {
        itemtype: () => Org.Dept,
        creator(this: Org, item: TOrg.IDept) {
            return new Org.Dept(this, undefined, item);
        },
    })) readonly dept!: Org.Dept;

    @JSON.Key()
    @Backface.Entity(() => Org.People, "org")
    @Editor.Field(Org, Editor.Owneds('peoples', {
        creator(this: Org): Org.Peoples {
            return new Org.Peoples(this);
        },
    })) readonly peoples!: Org.Peoples;

    @JSON.Key()
    @Backface.Entity(() => Org.Role, "org")
    @Editor.Field(Org, Editor.Owned('role', {
        itemtype: () => Org.Role,
        creator(this: Org, item: TOrg.IRole) {
            return new Org.Role(this, undefined, item);
        },
    })) readonly role!: Org.Role;

    @JSON.Key()
    @Backface.Entity(() => Org.Actor, "org")
    @Editor.Field(Org, Editor.Owneds('actors', {
        creator(this: Org) {
            return new Org.Actors(this);
        },
    })) readonly actors!: Org.Actors;

    @JSON.Key()
    @Backface.Entity(() => Org.Inspector, "org")
    @Editor.Field(Org, Editor.Owneds('inspectors', {
        creator(this: Org) {
            return new Org.Inspectors(this);
        },
    })) readonly inspectors!: Org.Inspectors;

    @Editor.Field(Org, Editor.RefFlattens('priviledges', {
        creator(this: Org, item: TOrg.IPriviledge) {
            return new Org.Priviledge(this, this.priviledge, item);
        },
        container(this: Org, item: Org.Priviledge): Org.Priviledge[] | undefined {
            return item?.parent?.priviledges.array;
        },
        children(this: Org, obj: Org | Org.Priviledge): Org.Priviledge[] | undefined {
            return obj instanceof Org ? obj.priviledge?.priviledges.array : obj.priviledges.array;
        }
    })) readonly priviledges!: Org.Priviledge[];

    @Editor.Field(Org, Editor.RefFlattens<Org, 'roles', TOrg.IRole, Org.Role>('roles', {
        creator(this: Org, item: TOrg.IRole) {
            return new Org.Role(this, this.role, item);
        },
        container(this: Org, item: Org.Role): Org.Role[] | undefined {
            return item?.parent?.roles?.array;
        },
        children(this: Org, obj: Org | Org.Role): Org.Role[] | undefined {
            return obj instanceof Org ? obj.role?.roles?.array : obj.roles?.array;
        }
    })) readonly roles!: Org.Role[];

    @Editor.Field(Org, Editor.RefFlattens<Org, 'depts', TOrg.IDept, Org.Dept>('depts', {
        creator(this: Org, item: TOrg.IDept) {
            return new Org.Dept(this, this.dept, item);
        },
        container(this: Org, item: Org.Dept): Org.Dept[] | undefined {
            return item?.parent?.depts.array;
        },
        children(this: Org, obj: Org | Org.Dept): Org.Dept[] | undefined {
            return obj instanceof Org ? obj.dept?.depts.array : obj.depts.array;
        },
    })) readonly depts!: Org.Dept[];

    constructor(public app: TApp.IAppService, setting?: TOrg.IOrg) {
        super(setting);
    }
}

export namespace Org {

    // const pxa: XArray<Priviledge, TOrg.IPriviledge, 'id'> = null as any;
    // const pa: Array<Priviledge> = null as any;
    // const pxap: TOrg.IPriviledge[] = pxa;
    // const pap: TOrg.IPriviledge[] = pa;

    export class People extends Editor.Editor<People, TOrg.IPeople> implements TOrg.IPeople {
        @JSON.Key()
        @Editor.Field(People, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        mobile!: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        email?: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        description!: string;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        disabled!: boolean;

        @JSON.Key()
        @Editor.Field(People, Editor.Type.owned)
        password?: string;

        @JSON.Key()
        @Editor.Field(People, Editor.RefOwneds('roles', {
            source(this: People) {
                return this.org.role;
            },
            creator(this: People) {
                return [] as Role[]
            },
        })) readonly roles!: Role[];

        @JSON.Key()
        @Editor.Field(People, Editor.RefOwned('dept', {
            itemtype: () => Dept,
            source(this: People) {
                return this.org.dept;
            },
        })) dept?: Dept;

        constructor(
            public org: Org,
            _people: TOrg.IPeople = {}
        ) {
            super(_people);
        }
    }

    @Editor.Collections(People)
    export class Peoples extends XArray<People, TOrg.IPeople, 'id'> {
        constructor(
            public org: Org,
            _peoples: TOrg.IPeople[] = []
        ) {
            super('id', ..._peoples);
        }

        override isT(val: People | TOrg.IPeople): val is People {
            return val instanceof People;
        }

        override construct(val: TOrg.IPeople): People {
            return new People(this.org, val);
        }
    }

    export class Priviledge extends Editor.Editor<Priviledge, TOrg.IPriviledge> implements TOrg.IPriviledge {
        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.numberid)
        readonly id!: number;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Type.owned)
        prop: JSON.Type;

        @JSON.Key()
        @Editor.Field(Priviledge, Editor.Owneds('priviledges', {
            creator(this: Priviledge): Priviledges {
                return new Priviledges(this.org, this);
            },
        })) readonly priviledges!: Priviledges;

        constructor(
            public org: Org,
            public parent: Priviledge | undefined,
            _priviledge: TOrg.IPriviledge = {}
        ) {
            super({
                ..._priviledge,
                name: !_priviledge.id ? 'priviledge.' : `${parent!.name}${_priviledge.name}${_priviledge.priviledges ? '.' : ''}`
            })
        }

        firstOf(privildege: PartialDeep<Org.Priviledge> | ((item: Priviledge) => boolean)): Priviledge | undefined {
            if (this.match(privildege)) {
                return this;
            }

            return this.priviledges?.firstOf(privildege);
        }
    }

    @Editor.Collections(Priviledge)
    export class Priviledges extends XArray<Priviledge, TOrg.IPriviledge, 'id'> implements Array<Priviledge> {
        constructor(
            public org: Org,
            public parent: Priviledge,
            _priviledges: TOrg.IPriviledge[] = []
        ) {
            super('id', ..._priviledges);
        }

        override isT(val: Priviledge | TOrg.IPriviledge): val is Priviledge {
            return val instanceof Priviledge;
        }

        override construct(val: TOrg.IPriviledge): Priviledge {
            return new Priviledge(this.org, this.parent, val);
        }

        override _onCreateDestroied(created?: Priviledge[], destroied?: Priviledge[]) {
            super._onCreateDestroied?.(created, destroied);

            const { priviledges: priviledges } = this.org;
            destroied && priviledges.remove(...destroied);
            created && priviledges.add(created, 0);
        }
    }

    export class Dept extends Editor.Editor<Dept, TOrg.IDept> implements TOrg.IDept {
        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        location!: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.Type.owned)
        description!: string;

        @JSON.Key()
        @Editor.Field(Dept, Editor.RefOwneds('peoples', {
            source(this: Dept) {
                return this.org.peoples;
            },
            creator(this: Dept) {
                return [] as People[];
            },
        })) readonly peoples!: People[];

        @JSON.Key()
        @Editor.Field(Dept, Editor.Owneds('depts', {
            creator(this: Dept) {
                return new Depts(this.org, this);
            },
        })) readonly depts!: Depts;

        @JSON.Key()
        @Editor.Field(Dept, Editor.RefParent('parent', {
            itemtype: () => Dept,
            source(this: Dept) {
                return this.org.dept;
            },
            children(this: Dept, parent?: Dept): Dept[] | undefined {
                return parent?.depts.array;
            },
            root(this: Dept): Dept {
                return this.org.dept;
            }
        })) parent?: Dept;

        constructor(
            public org: Org,
            parent: Dept | undefined,
            _dept: TOrg.IDept = {}
        ) {
            super((_dept.parent = parent as any, _dept));
        }

        firstOf(dept: PartialDeep<Org.Dept> | ((item: Dept) => boolean)): Dept | undefined {
            if (this.match(dept)) {
                return this;
            }

            return this.depts?.firstOf(dept);
        }
    }

    @Editor.Collections(Dept)
    export class Depts extends XArray<Dept, TOrg.IDept, 'id'> {
        constructor(
            public org: Org,
            public parent: Dept,
            _depts: TOrg.IDept[] = []
        ) {
            super('id', ..._depts);
        }

        override isT(val: Dept | TOrg.IDept): val is Dept {
            return val instanceof Dept;
        }

        override construct(val: TOrg.IDept): Dept {
            return new Dept(this.org, this.parent, val);
        }

        override _onCreateDestroied(created?: Dept[], destroied?: Dept[]): void {
            super._onCreateDestroied?.(created, destroied);

            const { depts } = this.org;
            destroied && depts.remove(...destroied);
            created && depts.add(created, 0);
        }
    }

    export class Role extends Editor.Editor<Role, TOrg.IRole> implements TOrg.IRole {
        @JSON.Key()
        @Editor.Field(Role, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Role, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Role, Editor.RefOwneds('priviledges', {
            source(this: Role) {
                return this.org.priviledge;
            },
            creator(this: Role) {
                return [] as Priviledge[]
            },
        })) readonly priviledges!: Priviledge[];

        @JSON.Key()
        @Editor.Field(Role, Editor.Owneds('roles', {
            creator(this: Role) {
                return new Roles(this.org, this);
            },
        })) readonly roles?: Roles;

        @JSON.Key()
        @Editor.Field(Role, Editor.RefParent('parent', {
            itemtype: () => Role,
            source(this: Role) {
                return this.org.role;
            },
            children(this: Role, parent?: Role): Role[] | undefined {
                return parent?.roles?.array;
            },
            root(this: Role): Role {
                return this.org.role;
            }
        })) parent?: Role;

        constructor(
            public org: Org,
            parent: Role | undefined,
            _role: TOrg.IRole = {}
        ) {
            super((_role.parent = parent as any, _role));
        }

        firstOf(role: PartialDeep<Org.Role> | ((item: Role) => boolean)): Role | undefined {
            if (this.match(role)) {
                return this;
            }

            return this.roles?.firstOf(role);
        }
    }

    @Editor.Collections(Role)
    export class Roles extends XArray<Role, TOrg.IRole, 'id'> {
        constructor(
            public org: Org,
            public parent: Role,
            _roles: TOrg.IRole[] = []
        ) {
            super('id', ..._roles);
        }

        override isT(val: Role | TOrg.IRole): val is Role {
            return val instanceof Role;
        }

        override construct(val: TOrg.IRole): Role {
            return new Role(this.org, this.parent, val);
        }

        override _onCreateDestroied(created?: Role[], destroied?: Role[]) {
            super._onCreateDestroied?.(created, destroied);

            const { roles: roles } = this.parent.org;
            destroied && roles.remove(...destroied);
            created && roles.add(created, 0);
        }
    }

    export class Actor extends Editor.Editor<Actor, TOrg.IActor> implements TOrg.IActor {
        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Actor, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwned('header', {
            itemtype: () => People,
            source(this: Actor) {
                return this.org.peoples;
            },
        })) header?: People;

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwned('prjowner', {
            itemtype: () => People,
            source(this: Actor) {
                return this.org.peoples;
            },
        })) prjowner?: People;

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwneds('leaders', {
            source(this: Actor) {
                return this.org.peoples;
            },
            creator(this: Actor) {
                return [] as People[];
            },
        })) readonly leaders!: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwneds('actors', {
            source(this: Actor) {
                return this.org.peoples;
            },
            creator(this: Actor) {
                return [] as People[];
            },
        })) readonly actors!: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwneds('domains', {
            source(this: Actor) {
                return this.org.peoples;
            },
            creator(this: Actor) {
                return [] as People[];
            },
        })) readonly domains!: People[];

        @JSON.Key()
        @Editor.Field(Actor, Editor.RefOwneds('sites', {
            source(this: Actor) {
                return this.org.peoples;
            },
            creator(this: Actor) {
                return [] as People[];
            },
        })) readonly sites!: People[];

        constructor(
            public org: Org,
            _actor: TOrg.IActor = {}
        ) {
            super(_actor);
        }
    }

    @Editor.Collections(Actor)
    export class Actors extends XArray<Actor, TOrg.IActor, 'id'> {
        constructor(
            public org: Org,
            _actors: TOrg.IActor[] = []
        ) {
            super('id', ..._actors);
        }

        override isT(val: Actor | TOrg.IActor): val is Actor {
            return val instanceof Actor;
        }

        override construct(val: TOrg.IActor): Actor {
            return new Actor(this.org, val);
        }
    }

    export class Inspector extends Editor.Editor<Inspector, TOrg.IInspector> implements TOrg.IInspector {
        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.RefOwned('kpileader', {
            itemtype: () => People,
            source(this: Inspector) {
                return this.org.peoples;
            },
        })) kpileader?: People;

        @JSON.Key()
        @Editor.Field(Inspector, Editor.RefOwneds('kpimembers', {
            source(this: Inspector) {
                return this.org.peoples;
            },
            creator(this: Inspector) {
                return [] as People[]
            },
        })) readonly kpimembers!: People[];

        @JSON.Key()
        @Editor.Field(Inspector, Editor.RefOwned('cdtorleader', {
            itemtype: () => People,
            source(this: Inspector) {
                return this.org.peoples;
            },
        })) cdtorleader?: People;

        constructor(
            public org: Org,
            _inspector: TOrg.IInspector = {}
        ) {
            super(_inspector);
        }
    }

    @Editor.Collections(Inspector)
    export class Inspectors extends XArray<Inspector, TOrg.IInspector, 'id'> {
        constructor(
            public org: Org,
            _inspectors: TOrg.IInspector[] = []
        ) {
            super('id', ..._inspectors);
        }

        override isT(val: Inspector | TOrg.IInspector): val is Inspector {
            return val instanceof Inspector;
        }

        override construct(val: TOrg.IInspector): Inspector {
            return new Inspector(this.org, val);
        }
    }
}