import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { Subscription, timer } from "rxjs";

@Directive({
    selector: "[timer]"
}) export class TimerDirective implements OnDestroy, OnChanges {
    private _props = Prop.Of<TimerDirective, {
        _subscription?: Subscription,
    }>(this);

    @Input('timer')
    get interval(): number | undefined {
        const { _props: { interval } } = this;
        return interval;
    }

    set interval(val: number | undefined) {
        this._props.interval = val;
    }

    @Input('countdown')
    get countdown(): number {
        const { _props: { countdown = 1 } } = this;
        return countdown;
    }

    set countdown(val: number) {
        const { _props: props } = this;
        props.countdown = val;
    }

    @Output('finish')
    get finish(): EventEmitter<boolean> {
        const { _props: props } = this;
        return props.finish || (
            props.finish = new EventEmitter()
        );
    }

    constructor(
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.unsubscribe();
        const { interval, countdown } = this;
        if (!_.isNumber(interval)) return;

        let _countdown = countdown;
        const { finish, _props: props } = this;
        props._subscription = timer(interval).subscribe(() => {
            if (--_countdown <= 0) {
                tick(() => { this.unsubscribe(); })
                finish.emit(true);
                return;
            }

            finish.emit(false)
        })
    }

    private unsubscribe() {
        const { _props: props } = this;
        props._subscription?.unsubscribe();
        delete props._subscription;
    }
}
